* {
  font-family: "Janna LT"
}

@import url(//db.onlinewebfonts.com/c/15c14edcc44ce5f6b601206439e7eba2?family=Janna+LT);

@font-face {
  font-family: "Janna LT";
  src: url("//db.onlinewebfonts.com/t/15c14edcc44ce5f6b601206439e7eba2.eot");
  src: url("//db.onlinewebfonts.com/t/15c14edcc44ce5f6b601206439e7eba2.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/15c14edcc44ce5f6b601206439e7eba2.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/15c14edcc44ce5f6b601206439e7eba2.woff") format("woff"), url("//db.onlinewebfonts.com/t/15c14edcc44ce5f6b601206439e7eba2.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/15c14edcc44ce5f6b601206439e7eba2.svg#Janna LT") format("svg");
}

.back:hover{
  transform: scale(1.1);
}

.osPic2:hover {
  transform: scale(1.1);
}
.connectBtn3:hover {
  transform: scale(1.2);
}
.connectBtn2:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
  color: rgb(0, 0, 0);
}
.mintMain{
  background: url('./assets/mintBg.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  min-height: 100vh;
  height: max-content;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

@media screen and (max-width: 499px) {

  .back{
    width: 40px;
    height: 40px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .2s;
  }  
.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 
}

.glitch {
  color: rgb(255, 255, 255);
  font-size: 40px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #1ec5fb;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #ba2e79;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-o-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-moz-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-o-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-moz-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 95%;
    margin-top: -13%;
   }
  
 
 
  /* Animation */
 .anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}


.in2 {
width: 100%;
display: flex;
 justify-content: center;
 font-size: 35px;
color: white;
font-weight: bold;
letter-spacing: 3px;
text-align: center;
margin-left: auto;
margin-right: auto;
}


.line-1 {
  position: relative;
   color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  width: 100%;
  display: flex;
   justify-content: center;
  flex-flow: column nowrap;
  font-size: 35px;
  color: white;
  font-weight: bold;
  letter-spacing: 3px;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 350px;
  }
}

.blinkBox{
 margin-top: 20%;
 margin-left: auto;
 margin-right: auto;
 justify-content: center;
 z-index: 10000000;
 line-height: 60px;
    }
  
  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
   
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  .box1NFTMob{
    width: 60%;
    height: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }
 .connectBtn2 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 16px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 200;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-top: 5%;
}
.osPic2 {
  width: 45px;
  height: 45px;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  border-radius: 100%;
  margin-left: 15px;
  margin-right: 15px;
  transition: transform .2s;

}

.mintBox1 img{
  width: 70%;  
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.mintBox1{
  flex-flow: column nowrap;
  display: flex;
  margin-bottom: 20%;
}

.mintBox{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 15%;
  margin-bottom: 15%;
  flex-flow: column nowrap;
 }


.connectBtn3 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 200px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  transition: transform .2s;
}

.mintCon{
  text-align: center;
  color: rgba(255, 255, 255, 0.699);
  font-weight: bold;
  margin-top: 5%;
  margin-bottom: 5%;
}

.mintCon2{
  text-align: left;
  color: white;
 font-weight: bold; 
 margin-left: auto;
 margin-right: auto;
 color: rgba(255, 255, 255, 0.815);

}
.listSection{
  margin-left: auto;
  margin-right: auto;
}
  
  .miniBarMain {
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1f73;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(86, 201, 255, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    color: rgb(0, 0, 0);
  }
  
  
  #container {
    width: 70vw;
    /* margin-left: 30vw auto;
    margin-right: 30vw auto;
    margin-bottom: 50vw auto; */
    margin: 5vw auto;
  
  }
  
  
  h1:nth-child(2) {
    color: #67cbf3;
    animation: glitch2 2.5s infinite;
  }
  
  h1:nth-child(3) {
    color: #e2f2ff;
    animation: glitch3 2.5s infinite;
  }
  
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
  
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
  
    10% {
      transform: none;
      opacity: 1;
    }
  
    27% {
      transform: none;
      opacity: 1;
    }
  
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
  
    35% {
      transform: none;
      opacity: 1;
    }
  
    52% {
      transform: none;
      opacity: 1;
    }
  
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
  
    50% {
      transform: none;
      opacity: 1;
    }
  
    72% {
      transform: none;
      opacity: 1;
    }
  
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
  
    80% {
      transform: none;
      opacity: 1;
    }
  
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  input {
    width: 200px;
    height: fit-content;
    text-align: center;
    background: rgba(0, 0, 0, 0.575);
    border: 2px #ffffff solid;
  }
   
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    /*letter-spacing: 8px;
      font-family: "Press Start 2P";
      font-weight: 400;*/
    /*Create overlap*/
    font-family: 'Archivo Black', sans-serif;
  
    margin: 0;
    line-height: 0;
    /*Animation*/
  
    animation: glitch1 2.5s infinite;
  }
    
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 20px;
  }
  
  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 5px;
    font-size: 17px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 100px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
   
  }

  .icons2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
  }

  .icons2 img {
    width: 50px;
    height: 50px;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 35px;
    height: 35px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
    border-radius: 100%;
   }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform .2s;

  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    text-align: center;
    font-size: 20px;
    color: #acf9ff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 260px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: #0b0c11;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  
    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100475218662789181/NFTHouse9_blockchain_network_background_image_cyber_punk_theme__3ce5a6b3-d55d-4562-94e6-ed143a568181.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
   
  }
  
  .perks {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #040d1a;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(4,13,26,1) 0%, rgba(4,13,26,0) 47%, rgba(11,12,17,0.8239670868347339) 100%), url('./assets/nft.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .videoContainer {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100459043526561934/NFTHouse9_doge_coin_cyber_punk_theme_64k_6dc18e25-ad22-487f-9d98-6eada8a42fd1.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #040d1a;
  
  }
  
  .footer {
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;  
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10px;
    text-align: center;
  }
  
  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 70%;
    margin-top: 20px;
  }
  
  .about2 img {
    width: 330px;
    height: 330px;
    border-radius: 50px;
    margin-right: 80px;
    border: 2px rgb(35, 189, 250) solid;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    margin-bottom: 100px;
    /* From https://css.glass */
    background: rgb(242, 205, 68);
    background: linear-gradient(40deg, rgba(242, 205, 68, 0.3345623230666257) 0%, rgba(40, 27, 47, 0) 35%, rgba(18, 243, 251, 0.269955581493592) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgb(12, 193, 248);
    margin-top: 20px;
    box-shadow: 0 0 15px #64dafd;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .about img {
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .video {
    width: 40vh;
    height: 22.5vh; 
     margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    display: flex;
    font-size: 38px;
    color: white;
    font-weight: bold;
     text-align: center;
     margin-left: auto;
     margin-right: auto;
   }

   .storyH2New {
    display: flex;
    font-size: 38px;
    color: white;
    font-weight: bold;
     text-align: center;
     margin-left: auto;
     margin-right: auto;
   }
  
  .teamH {
    display: flex;
    font-size: 48px;
    color: white;
    font-weight: bold;
    text-align: center;
     margin-left: auto;
     margin-right: auto;
     display: none;
         display: none;

  }
  
  .storyH2 {
    display: flex;
    font-size: 45px;
    color: white;
    font-weight: bold;
    text-align: center;
    display: none;
  }
  
  .storyH3 {
    display: flex;
    font-size: 48px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: none;

  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: center;
     margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
     }
  
  .perksMain{
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 15px;
    text-align: center;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon3 {
    font-size: 15px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  
  .teamCon {
    font-size: 15px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    display: none;
  }
  
  .teamCon2 {
    font-size: 15px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
   }

  .perksCon {
    font-size: 15px;
    text-align: center;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .storyCon3Hl {
    font-size: 15px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: bold;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 100%;
  }
  
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid white;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #7700ff;
    letter-spacing: 2px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  }
  
  #fontSize:hover {
    color: #13fafa;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc1900;
    color: #1cb5f1;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    width: 250px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    transition: transform .2s;
    font-weight: bold;
    border: 2px solid white;
  }
  
  .btnfos-0-3:hover {
    border: 2px solid #1cb5f1;
    color: white;
  }
  
  .nftamount {
    color: #ffffff;
    font-size: 65px;
    font-family: 'Archivo Black', sans-serif;
    text-align: center;
    -webkit-text-stroke: 2px #21bcfa;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 28px;
    text-shadow: -3px 0 #1ee03e;
    letter-spacing: 2px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: none;
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
    
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .mainNFT {
    width: 100%;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    display: none;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: 'Archivo Black', sans-serif;
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 15px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 130px;
    height: 156px;
  }
  
  .logo2 {
    width: 140px;
    height: 168px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 17px;
    cursor: pointer;
    z-index: 10000;
    color: #fafafa;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }
  
  .faq__content {
  
    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgba(255, 255, 255, 0.699);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #0b0c11;
    height: fit-content;
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    display: none;
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 350px;
    height: 350px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }
  
  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }
  
  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }
  
  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }
  
  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container img {
    width: 45%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }
  
  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
  
  }
  
  .container:hover img {
    opacity: 0.3;
  }
  
  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPic {
    width: 100%;
    position: absolute;
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  
  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }
  
  .chatBox1 {
    display: none;
  
  }
  
  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }
  
  .chatBox2 {
    display: none;
  
  }
  
  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }
  
  .chatBox3 {
    display: none;
  
  }
  
  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }
  
  .chatBox4 {
    display: none;
  
  }
  
  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }
  
  .chatBox5 {
    display: none;
  
  }
  
  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }
  
  .chatBox6 {
    display: none;
  
  }
  
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  
  }
  
  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: 'Archivo Black', sans-serif;
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 55px;
    height: 55px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
    margin-top: -15%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .row1{
    display: flex;
    flex-flow: column nowrap;
    color: white;
    justify-content: space-between;
    }
  
  .coloumn1{
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }  
  
  .figure1{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
  
  .figure2{
    text-align: center;
    font-size: 14px;
  }
  
  .box1{
    display: flex;
    flex-flow: row nowrap;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.116);
  border-radius: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
     margin-top: 20%;
    margin-bottom: 20%;
     margin-left: auto;
    margin-right: auto;
    padding: 20px;
    z-index: 1;
    }
  
  .boxMain{
    position: relative;
  }
  
  .boxMain{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
  
  .figureSection{ 
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .boxCon{
    width: 60%;
    }
  
  .nftT{
    color: #4cadc5;
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
    margin-bottom: 8%;
  
  }
  
  .box1NFT{
    width: 45%;
    margin-left: 57%;
      z-index: 10000000000000000;
    position: absolute;
    margin-top: -11%;
    display: none;
  }

  .box1NFTMob{
    width: 60%;
    height: 60%;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .box1NFTDiv{
    margin-right: auto;
   }
  
  .nftT2{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #ac64a5;
    margin-bottom: 8%;
  }
  
  .headerAndCon{
    background-color: #0038f9;
    width: fit-content;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .back{
    width: 40px;
    height: 40px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .2s;
  }  
.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 
}

.glitch {
  color: rgb(255, 255, 255);
  font-size: 50px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #1ec5fb;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #ba2e79;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-o-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-moz-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-o-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-moz-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}
  .mainNFTMob{
    width: 90%;
    margin-left: 6%;
    margin-top: -20%;
  }
  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
   
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
 
 .connectBtn2 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 16px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 200;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-top: 5%;
}
.osPic2 {
  width: 45px;
  height: 45px;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  border-radius: 100%;
  margin-left: 15px;
  margin-right: 15px;
  transition: transform .2s;

}

.mintBox1 img{
  width: 70%;  
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.mintBox1{
  flex-flow: column nowrap;
  display: flex;
  margin-bottom: 20%;
}

.mintBox{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 15%;
  margin-bottom: 15%;
  flex-flow: column nowrap;
 }


.connectBtn3 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 200px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  transition: transform .2s;
}

.mintCon{
  text-align: center;
  color: rgba(255, 255, 255, 0.699);
  font-weight: bold;
  margin-top: 5%;
  margin-bottom: 5%;
}

.mintCon2{
  text-align: left;
  color: white;
 font-weight: bold; 
 margin-left: auto;
 margin-right: auto;
 color: rgba(255, 255, 255, 0.815);

}
.listSection{
  margin-left: auto;
  margin-right: auto;
}

  
  .miniBarMain {
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1f73;
    display: flex;
    justify-content: space-between;
  }
  
  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(86, 201, 255, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    color: rgb(0, 0, 0);
  }
  
  
  #container {
    width: 70vw;
    /* margin-left: 30vw auto;
    margin-right: 30vw auto;
    margin-bottom: 50vw auto; */
    margin: 5vw auto;
  
  }
  
  
  h1:nth-child(2) {
    color: #67cbf3;
    animation: glitch2 2.5s infinite;
  }
  
  h1:nth-child(3) {
    color: #e2f2ff;
    animation: glitch3 2.5s infinite;
  }
  
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
  
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
  
    10% {
      transform: none;
      opacity: 1;
    }
  
    27% {
      transform: none;
      opacity: 1;
    }
  
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
  
    35% {
      transform: none;
      opacity: 1;
    }
  
    52% {
      transform: none;
      opacity: 1;
    }
  
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
  
    50% {
      transform: none;
      opacity: 1;
    }
  
    72% {
      transform: none;
      opacity: 1;
    }
  
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
  
    80% {
      transform: none;
      opacity: 1;
    }
  
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  input {
    width: 200px;
    height: fit-content;
    text-align: center;
    background: rgba(0, 0, 0, 0.575);
    border: 2px #ffffff solid;
  }
   
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    /*letter-spacing: 8px;
      font-family: "Press Start 2P";
      font-weight: 400;*/
    /*Create overlap*/
    font-family: 'Archivo Black', sans-serif;
  
    margin: 0;
    line-height: 0;
    /*Animation*/
  
    animation: glitch1 2.5s infinite;
  }
    
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 20px;
  }
  
  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 7px;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 130px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
   
  }

  .icons2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
  }

  .icons2 img {
    width: 50px;
    height: 50px;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
    border-radius: 100%;
   }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform .2s;

  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    text-align: center;
    font-size: 20px;
    color: #acf9ff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 260px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: #0b0c11;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  
    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100475218662789181/NFTHouse9_blockchain_network_background_image_cyber_punk_theme__3ce5a6b3-d55d-4562-94e6-ed143a568181.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
   
  }
  
  .perks {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #040d1a;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(4,13,26,1) 0%, rgba(4,13,26,0) 47%, rgba(11,12,17,0.8239670868347339) 100%), url('./assets/nft.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .videoContainer {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100459043526561934/NFTHouse9_doge_coin_cyber_punk_theme_64k_6dc18e25-ad22-487f-9d98-6eada8a42fd1.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #040d1a;
  
  }
  
  .footer {
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;  
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10px;
    text-align: center;
  }
  
  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 70%;
    margin-top: 20px;
  }
  
  .about2 img {
    width: 330px;
    height: 330px;
    border-radius: 50px;
    margin-right: 80px;
    border: 2px rgb(35, 189, 250) solid;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    margin-bottom: 100px;
    /* From https://css.glass */
    background: rgb(242, 205, 68);
    background: linear-gradient(40deg, rgba(242, 205, 68, 0.3345623230666257) 0%, rgba(40, 27, 47, 0) 35%, rgba(18, 243, 251, 0.269955581493592) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgb(12, 193, 248);
    margin-top: 20px;
    box-shadow: 0 0 15px #64dafd;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .about img {
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .video {
    width: 70vh;
    height: 39.375vh; 
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    display: flex;
    font-size: 48px;
    color: white;
    font-weight: bold;
     text-align: center;
     margin-left: auto;
     margin-right: auto;
   }

   .storyH2New {
    display: flex;
    font-size: 48px;
    color: white;
    font-weight: bold;
     text-align: center;
     margin-left: auto;
     margin-right: auto;
   }
  
  .teamH {
    display: flex;
    font-size: 48px;
    color: white;
    font-weight: bold;
    text-align: center;
     margin-left: auto;
     margin-right: auto;
     display: none;
         display: none;

  }
  
  .storyH2 {
    display: flex;
    font-size: 48px;
    color: white;
    font-weight: bold;
    text-align: center;
    display: none;
  }
  
  .storyH3 {
    display: flex;
    font-size: 48px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: none;

  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: center;
     margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
     }
  
  .perksMain{
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 16px;
    text-align: center;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon3 {
    font-size: 16px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  
  .teamCon {
    font-size: 16px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    display: none;
  }
  
  .teamCon2 {
    font-size: 16px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
   }

  .perksCon {
    font-size: 16px;
    text-align: center;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .storyCon3Hl {
    font-size: 16px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: bold;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 100%;
  }
  
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid white;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #7700ff;
    letter-spacing: 2px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  }
  
  #fontSize:hover {
    color: #13fafa;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc1900;
    color: #1cb5f1;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    width: 250px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    transition: transform .2s;
    font-weight: bold;
    border: 2px solid white;
  }
  
  .btnfos-0-3:hover {
    border: 2px solid #1cb5f1;
    color: white;
  }
  
  .nftamount {
    color: #ffffff;
    font-size: 65px;
    font-family: 'Archivo Black', sans-serif;
    text-align: center;
    -webkit-text-stroke: 2px #21bcfa;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 28px;
    text-shadow: -3px 0 #1ee03e;
    letter-spacing: 2px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: none;
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
    
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .mainNFT {
    width: 100%;
    margin-left: 3%;
    display: flex;
    display: none;
    justify-content: center;
  }
 
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: 'Archivo Black', sans-serif;
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 15px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 130px;
    height: 156px;
  }
  
  .logo2 {
    width: 140px;
    height: 168px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 17px;
    cursor: pointer;
    z-index: 10000;
    color: #fafafa;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }
  
  .faq__content {
  
    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #0b0c11;
    height: fit-content;
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    display: none;
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 350px;
    height: 350px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }
  
  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }
  
  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }
  
  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }
  
  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container img {
    width: 45%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }
  
  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
  
  }
  
  .container:hover img {
    opacity: 0.3;
  }
  
  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPic {
    width: 100%;
    position: absolute;
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  
  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }
  
  .chatBox1 {
    display: none;
  
  }
  
  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }
  
  .chatBox2 {
    display: none;
  
  }
  
  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }
  
  .chatBox3 {
    display: none;
  
  }
  
  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }
  
  .chatBox4 {
    display: none;
  
  }
  
  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }
  
  .chatBox5 {
    display: none;
  
  }
  
  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }
  
  .chatBox6 {
    display: none;
  
  }
  
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  
  }
  
  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: 'Archivo Black', sans-serif;
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 55px;
    height: 55px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 23%;
    margin-left: 83%;
    margin-top: -10%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .row1{
    display: flex;
    flex-flow: column nowrap;
    color: white;
    justify-content: space-between;
    }
  
  .coloumn1{
    flex-flow: column nowrap;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }  
  
  .figure1{
    font-weight: bold;
    font-size: 23px;
    text-align: center;
  }
  
  .figure2{
    text-align: center;
  }
  
  .box1{
    display: flex;
    flex-flow: row nowrap;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.116);
  border-radius: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
     margin-top: 20%;
    margin-bottom: 20%;
     margin-left: auto;
    margin-right: auto;
    padding: 20px;
    z-index: 1;
    }
  
  .boxMain{
    position: relative;
  }
  
  .boxMain{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
  
  .figureSection{ 
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .boxCon{
    width: 60%;
    }
  
  .nftT{
    color: #4cadc5;
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
    margin-bottom: 8%;
  
  }
  
  .box1NFT{
    width: 45%;
    margin-left: 57%;
      z-index: 10000000000000000;
    position: absolute;
    margin-top: -11%;
    display: none;
  }

  .box1NFTMob{
    width: 55%;
    height: 55%;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .box1NFTDiv{
    margin-right: auto;
   }
  
  .nftT2{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #ac64a5;
    margin-bottom: 8%;
  }
  
  .headerAndCon{
    background-color: #0038f9;
    width: fit-content;
  }
  
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 95%;
    margin-top: -13%;
   }
  
 
 
  /* Animation */
 .anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}


.in2 {
width: 100%;
display: flex;
 justify-content: center;
flex-flow: column nowrap;
font-size: 50px;
color: white;
font-weight: bold;
letter-spacing: 3px;
text-align: center;
}


.line-1 {
  position: relative;
   color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  width: 100%;
  display: flex;
   justify-content: center;
  flex-flow: column nowrap;
  font-size: 50px;
  color: white;
  font-weight: bold;
  letter-spacing: 3px;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 470px;
  }
}

.blinkBox{
 margin-top: 20%;
 margin-left: auto;
 margin-right: auto;
 justify-content: center;
 z-index: 10000000;
 line-height: 70px;
 }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .back{
    width: 50px;
    height: 50px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .2s;
  }
.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 
}

.glitch {
  color: rgb(255, 255, 255);
  font-size: 80px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #1ec5fb;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #ba2e79;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-o-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-moz-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-o-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-moz-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}
  .mainNFT {
    width: 100%;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    display: none;
  }
  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
   
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  .box1NFTMob{
  display: none;
 }
 .connectBtn2 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 16px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 200;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
}
.osPic2 {
  width: 45px;
  height: 45px;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  border-radius: 100%;
  margin-left: 15px;
  margin-right: 15px;
  transition: transform .2s;

}

.mintBox1 img{
  width: 50%;  
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.mintBox1{
  flex-flow: column nowrap;
  display: flex;
  margin-bottom: 10%;
}

.mintBox{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  justify-content: space-between;
  width: 70%;
  margin-top: 15%;
  margin-bottom: 15%;
  flex-flow: column nowrap;
 }


.connectBtn3 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 200px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  transition: transform .2s;
}

.mintCon{
  text-align: center;
  color: rgba(255, 255, 255, 0.699);
  font-weight: bold;
  margin-top: 5%;
  margin-bottom: 5%;
}

.mintCon2{
  text-align: left;
  color: white;
 font-weight: bold; 
 margin-left: auto;
 margin-right: auto;
 color: rgba(255, 255, 255, 0.815);

}
.listSection{
  margin-left: auto;
  margin-right: auto;
}

  .miniBarMain {
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1f73;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(86, 201, 255, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: max-content;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    color: rgb(0, 0, 0);
  }
  
  
  #container {
    width: 70vw;
    /* margin-left: 30vw auto;
    margin-right: 30vw auto;
    margin-bottom: 50vw auto; */
    margin: 5vw auto;
  
  }
  
  
  h1:nth-child(2) {
    color: #67cbf3;
    animation: glitch2 2.5s infinite;
  }
  
  h1:nth-child(3) {
    color: #e2f2ff;
    animation: glitch3 2.5s infinite;
  }
  
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
  
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
  
    10% {
      transform: none;
      opacity: 1;
    }
  
    27% {
      transform: none;
      opacity: 1;
    }
  
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
  
    35% {
      transform: none;
      opacity: 1;
    }
  
    52% {
      transform: none;
      opacity: 1;
    }
  
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
  
    50% {
      transform: none;
      opacity: 1;
    }
  
    72% {
      transform: none;
      opacity: 1;
    }
  
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
  
    80% {
      transform: none;
      opacity: 1;
    }
  
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  input {
    width: 200px;
    height: fit-content;
    text-align: center;
    background: rgba(0, 0, 0, 0.575);
    border: 2px #ffffff solid;
  }
   
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    /*letter-spacing: 8px;
      font-family: "Press Start 2P";
      font-weight: 400;*/
    /*Create overlap*/
    font-family: 'Archivo Black', sans-serif;
  
    margin: 0;
    line-height: 0;
    /*Animation*/
  
    animation: glitch1 2.5s infinite;
  }
    
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 20px;
  }
  
  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 150px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
   
  }

  .icons2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
  }

  .icons2 img {
    width: 50px;
    height: 50px;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
    border-radius: 100%;
   }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    text-align: center;
    font-size: 20px;
    color: #acf9ff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 260px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: #0b0c11;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  
    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100475218662789181/NFTHouse9_blockchain_network_background_image_cyber_punk_theme__3ce5a6b3-d55d-4562-94e6-ed143a568181.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
   
  }
  
  .perks {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #040d1a;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(4,13,26,1) 0%, rgba(4,13,26,0) 47%, rgba(11,12,17,0.8239670868347339) 100%), url('./assets/nft.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .videoContainer {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100459043526561934/NFTHouse9_doge_coin_cyber_punk_theme_64k_6dc18e25-ad22-487f-9d98-6eada8a42fd1.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #040d1a;
  
  }
  
  .footer {
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;  
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10px;
    text-align: center;
  }
  
  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 70%;
    margin-top: 20px;
  }
  
  .about2 img {
    width: 330px;
    height: 330px;
    border-radius: 50px;
    margin-right: 80px;
    border: 2px rgb(35, 189, 250) solid;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    margin-bottom: 100px;
    /* From https://css.glass */
    background: rgb(242, 205, 68);
    background: linear-gradient(40deg, rgba(242, 205, 68, 0.3345623230666257) 0%, rgba(40, 27, 47, 0) 35%, rgba(18, 243, 251, 0.269955581493592) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgb(12, 193, 248);
    margin-top: 20px;
    box-shadow: 0 0 15px #64dafd;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .about img {
    width: 80%;
    height: 80%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .video {
    width: 90vh;
    height: 50.625vh; 
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);

  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    display: flex;
    font-size: 60px;
    color: white;
    font-weight: bold;
     text-align: center;
     margin-left: auto;
     margin-right: auto;
   }
   .storyH2New {
    display: flex;
    font-size: 48px;
    color: white;
    font-weight: bold;
     text-align: center;
     margin-left: auto;
     margin-right: auto;
   }
  .teamH {
    display: flex;
    font-size: 60px;
    color: white;
    font-weight: bold;
    text-align: center;
     margin-left: auto;
     margin-right: auto;
     display: none;
         display: none;

  }
  
  .storyH2 {
    display: flex;
    font-size: 60px;
    color: white;
    font-weight: bold;
    text-align: center;
    display: none;
  }
  
  .storyH3 {
    display: flex;
    font-size: 60px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: none;

  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: center;
     margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
     }
  
  .perksMain{
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon3 {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  
  .teamCon {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    display: none;
  }
  
  .teamCon2 {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
   }

  .perksCon {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .storyCon3Hl {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: bold;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 100%;
  }
  
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid white;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #7700ff;
    letter-spacing: 2px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  }
  
  #fontSize:hover {
    color: #13fafa;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc1900;
    color: #1cb5f1;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    width: 250px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    transition: transform .2s;
    font-weight: bold;
    border: 2px solid white;
  }
  
  .btnfos-0-3:hover {
    border: 2px solid #1cb5f1;
    color: white;
  }
  
  .nftamount {
    color: #ffffff;
    font-size: 65px;
    font-family: 'Archivo Black', sans-serif;
    text-align: center;
    -webkit-text-stroke: 2px #21bcfa;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 28px;
    text-shadow: -3px 0 #1ee03e;
    letter-spacing: 2px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: none;
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 95%;
  }
  .mainNFTMob{
    width: 90%;
    margin-left: 6%;
    margin-top: -20%;
  }
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .mainNFT {
    width: 100%;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    display: none;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: 'Archivo Black', sans-serif;
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 140px;
    height: 168px;
  }
  
  .logo2 {
    width: 140px;
    height: 168px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 17px;
    cursor: pointer;
    z-index: 10000;
    color: #fafafa;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }
  
  .faq__content {
  
    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #0b0c11;
    height: fit-content;
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    display: none;
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 350px;
    height: 350px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }
  
  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }
  
  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }
  
  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }
  
  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container img {
    width: 45%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }
  
  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
  
  }
  
  .container:hover img {
    opacity: 0.3;
  }
  
  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPic {
    width: 100%;
    position: absolute;
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  
  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }
  
  .chatBox1 {
    display: none;
  
  }
  
  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }
  
  .chatBox2 {
    display: none;
  
  }
  
  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }
  
  .chatBox3 {
    display: none;
  
  }
  
  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }
  
  .chatBox4 {
    display: none;
  
  }
  
  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }
  
  .chatBox5 {
    display: none;
  
  }
  
  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }
  
  .chatBox6 {
    display: none;
  
  }
  
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  
  }
  
  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: 'Archivo Black', sans-serif;
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 65px;
    height: 65px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
    margin-top: -8%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .row1{
    display: flex;
    flex-flow: column nowrap;
    color: white;
    justify-content: space-between;
    }
  
  .coloumn1{
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }  
  
  .figure1{
    font-weight: bold;
    font-size: 23px;
    text-align: center;
  }
  
  .figure2{
    text-align: center;
  }
  
  .box1{
    display: flex;
    flex-flow: column nowrap;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.116);
  border-radius: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
     margin-top: 20%;
    margin-bottom: 20%;
     margin-left: auto;
    margin-right: auto;
    padding: 20px;
    z-index: 1;
    }
  
  .boxMain{
    position: relative;
  }
  
  .boxMain{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
  
  .figureSection{ 
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .boxCon{
    width: 60%;
    }
  
  .nftT{
    color: #4cadc5;
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
    margin-bottom: 8%;
  
  }
  
  .box1NFT{
    width: 45%;
    margin-left: 57%;
      z-index: 10000000000000000;
    position: absolute;
    margin-top: -11%;
  }
  
  .box1NFTDiv{
    margin-right: auto;
   }
  
  .nftT2{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #ac64a5;
    margin-bottom: 8%;
  }
  
  .headerAndCon{
    background-color: #0038f9;
    width: fit-content;
  }

  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 95%;
    margin-top: -13%;
   }
  
 
 
  /* Animation */
 .anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}


.in2 {
width: 100%;
display: flex;
 justify-content: center;
flex-flow: column nowrap;
font-size: 60px;
color: white;
font-weight: bold;
letter-spacing: 3px;
text-align: center;
}


.line-1 {
  position: relative;
   color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  width: 100%;
  display: flex;
   justify-content: center;
  flex-flow: column nowrap;
  font-size: 60px;
  color: white;
  font-weight: bold;
  letter-spacing: 3px;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 540px;
  }
}

.blinkBox{
 margin-top: 20%;
 margin-left: auto;
 margin-right: auto;
 justify-content: center;
 z-index: 10000000;
 line-height: 80px;
}

.box1NFTMob{
  display: none;
 }

}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .back{
    width: 50px;
    height: 50px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .2s;
  }
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
   
  }
  
  .glitch {
    color: rgb(255, 255, 255);
    font-size: 100px;
    text-transform: upercase;
    position: relative;
    display: inline-block;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }
  
  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }
  
  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }
  
  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }
  
    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }
  
    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }
  
    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }
  
    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }
  
    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }
  
    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }
  
    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }
  
    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }
  
    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }
  
    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }
  
    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }
  
    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }
  
    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }
  
    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }
  
    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }
  
    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }
  
    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }
  
    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }
  
    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }
  
    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }
  
    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }
  
    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }
  
    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }
  
    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }
  
  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  
  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }
  
    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }
  
    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }
  
    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }
  
    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }
  
    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }
  
    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }
  
    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }
  
    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }
  
    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }
  
    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }
  
    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }
  
    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }
  
    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }
  
    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }
  
    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }
  
    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }
  
    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }
  
    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }
  
    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }
  
    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }
  
    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }
  
    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }
  
    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }
  
    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }
  
    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }
  
    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }
  
    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }
  
    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 95%;
    margin-top: -13%;
    position: relative;
    margin-bottom: 20%;
  }
  
 
 
  /* Animation */
 .anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}


.in2 {
width: 100%;
display: flex;
 justify-content: center;
flex-flow: column nowrap;
font-size: 60px;
color: white;
font-weight: bold;
letter-spacing: 3px;

}


.line-1 {
  position: relative;
   color: rgb(255, 255, 255);
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
  width: 100%;
  display: flex;
   justify-content: center;
  flex-flow: column nowrap;
  font-size: 60px;
  color: white;
  font-weight: bold;
  letter-spacing: 3px;
}

@keyframes typewriter {
  from {
    width: 0;
  }

  to {
    width: 540px;
  }
}

.blinkBox{
 margin-top: 20%;
 margin-left: 5%;
 z-index: 10000000;
 line-height: 80px;
}

.box1NFTMob{
  display: none;
 }
 .mainNFTMob{
  width: 50%;
  position: absolute;
  margin-left: 50%;
}
  .connectBtn2 {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 18px;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 230px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  }
  .osPic2 {
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
    border-radius: 100%;
    margin-left: 15px;
    margin-right: 15px;
    transition: transform .2s;

  }
  
  .mintBox1 img{
    width: 60%;  
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .mintBox1{
    flex-flow: column nowrap;
    display: flex;
  
  }
  
  .mintBox{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    justify-content: space-between;
    width: 70%;
    margin-top: 15%;
    margin-bottom: 15%;
   }
  
  
  .connectBtn3 {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 200px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
    transition: transform .2s;
  }
  
  .mintCon{
    text-align: center;
    color: rgba(255, 255, 255, 0.699);
    font-weight: bold;
    margin-top: 6%;
    margin-bottom: 6%;
  }
  
  .mintCon2{
    text-align: left;
    color: white;
   font-weight: bold; 
   margin-left: auto;
   margin-right: auto;
   color: rgba(255, 255, 255, 0.815);
  
  }
  .listSection{
    margin-left: auto;
    margin-right: auto;
  }

  .teamCon2{
  display: none;
}
  
  .box1NFTMob{
    display: none;
   }
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    color: rgb(0, 0, 0);
  }
  
  
  #container {
    width: 70vw;
    /* margin-left: 30vw auto;
    margin-right: 30vw auto;
    margin-bottom: 50vw auto; */
    margin: 5vw auto;
  
  }
  
  
  h1:nth-child(2) {
    color: #67cbf3;
    animation: glitch2 2.5s infinite;
  }
  
  h1:nth-child(3) {
    color: #e2f2ff;
    animation: glitch3 2.5s infinite;
  }
  
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
  
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
  
    10% {
      transform: none;
      opacity: 1;
    }
  
    27% {
      transform: none;
      opacity: 1;
    }
  
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
  
    35% {
      transform: none;
      opacity: 1;
    }
  
    52% {
      transform: none;
      opacity: 1;
    }
  
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
  
    50% {
      transform: none;
      opacity: 1;
    }
  
    72% {
      transform: none;
      opacity: 1;
    }
  
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
  
    80% {
      transform: none;
      opacity: 1;
    }
  
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  input {
    width: 200px;
    height: fit-content;
    text-align: center;
    background: rgba(0, 0, 0, 0.575);
    border: 2px #ffffff solid;
  }
  
  .miniBarMain {
    display: none;
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    /*letter-spacing: 8px;
      font-family: "Press Start 2P";
      font-weight: 400;*/
    /*Create overlap*/
    font-family: 'Archivo Black', sans-serif;
  
    margin: 0;
    line-height: 0;
    /*Animation*/
  
    animation: glitch1 2.5s infinite;
  }
  
  .littleNav {
    display: none;
  }
  
  .navbarMain {
    display: none;
  }
  
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 20px;
  }
  
  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 10px;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 150px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
   
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
    border-radius: 100%;
   }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform .2s;

  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    text-align: center;
    font-size: 20px;
    color: #acf9ff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 260px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: #0b0c11;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  
    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100475218662789181/NFTHouse9_blockchain_network_background_image_cyber_punk_theme__3ce5a6b3-d55d-4562-94e6-ed143a568181.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
   
  }
  
  .perks {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    background-color: #040d1a;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(4,13,26,1) 0%, rgba(4,13,26,0) 47%, rgba(11,12,17,0.8239670868347339) 100%), url('./assets/nft.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  
  .videoContainer {
    padding-top: 3%;
    padding-bottom: 3%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100459043526561934/NFTHouse9_doge_coin_cyber_punk_theme_64k_6dc18e25-ad22-487f-9d98-6eada8a42fd1.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #040d1a;
  
  }
  
  .footer {
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;  
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 10px;
  }
  
  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 70%;
    margin-top: 20px;
  }
  
  .about2 img {
    width: 330px;
    height: 330px;
    border-radius: 50px;
    margin-right: 80px;
    border: 2px rgb(35, 189, 250) solid;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    margin-bottom: 100px;
    /* From https://css.glass */
    background: rgb(242, 205, 68);
    background: linear-gradient(40deg, rgba(242, 205, 68, 0.3345623230666257) 0%, rgba(40, 27, 47, 0) 35%, rgba(18, 243, 251, 0.269955581493592) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgb(12, 193, 248);
    margin-top: 20px;
    box-shadow: 0 0 15px #64dafd;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .about img {
    width: 50%;
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .video {
    width: 120vh;
    height: 67.5vh;    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH2New{
    display: none;
  }

  .storyH {
    display: flex;
    font-size: 60px;
    color: white;
    font-weight: bold;
     text-align: center;
     margin-left: auto;
   }
  
  .teamH {
    display: flex;
    font-size: 60px;
    color: white;
    font-weight: bold;
    margin-right: auto;
  }
  
  .storyH2 {
    display: flex;
    font-size: 60px;
    color: white;
    font-weight: bold;
    margin-right: auto;
  }
  
  .storyH3 {
    display: flex;
    font-size: 60px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: right;
     margin-top: auto;
    margin-bottom: auto;
     }
  
  .perksMain{
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 18px;
    text-align: left;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon3 {
    font-size: 18px;
    text-align: right;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  
  .teamCon {
    font-size: 18px;
    text-align: left;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  
  
  .perksCon {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .storyCon3Hl {
    font-size: 18px;
    text-align: center;
    color: white;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: bold;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 100%;
  }
  
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid white;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #7700ff;
    letter-spacing: 2px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  }
  
  #fontSize:hover {
    color: #13fafa;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc1900;
    color: #1cb5f1;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    width: 250px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    transition: transform .2s;
    font-weight: bold;
    border: 2px solid white;
  }
  
  .btnfos-0-3:hover {
    border: 2px solid #1cb5f1;
    color: white;
  }
  
  .nftamount {
    color: #ffffff;
    font-size: 65px;
    font-family: 'Archivo Black', sans-serif;
    text-align: center;
    -webkit-text-stroke: 2px #21bcfa;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 28px;
    text-shadow: -3px 0 #1ee03e;
    letter-spacing: 2px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
  
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
   
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
     
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
 
    
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: 'Archivo Black', sans-serif;
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 140px;
    height: 168px;
  }
  
  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 17px;
    cursor: pointer;
    z-index: 10000;
    color: #fafafa;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }
  
  .faq__content {
  
    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #0b0c11;
    height: fit-content;
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 350px;
    height: 350px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }
  
  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }
  
  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }
  
  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }
  
  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container img {
    width: 45%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }
  
  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
  
  }
  
  .container:hover img {
    opacity: 0.3;
  }
  
  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPic {
    width: 100%;
    position: absolute;
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  
  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }
  
  .chatBox1 {
    display: none;
  
  }
  
  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }
  
  .chatBox2 {
    display: none;
  
  }
  
  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }
  
  .chatBox3 {
    display: none;
  
  }
  
  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }
  
  .chatBox4 {
    display: none;
  
  }
  
  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }
  
  .chatBox5 {
    display: none;
  
  }
  
  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }
  
  .chatBox6 {
    display: none;
  
  }
  
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  
  }
  
  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: 'Archivo Black', sans-serif;
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 65px;
    height: 65px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
    margin-top: -8%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .row1{
    display: flex;
    flex-flow: column nowrap;
    color: white;
    justify-content: space-between;
    }
  
  .coloumn1{
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }  
  
  .figure1{
    font-weight: bold;
    font-size: 23px;
    text-align: center;
  }
  
  .figure2{
    text-align: center;
  }
  
  .box1{
    display: flex;
    flex-flow: column nowrap;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.116);
  border-radius: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
     margin-top: 20%;
    margin-bottom: 20%;
     margin-left: auto;
    margin-right: auto;
    padding: 20px;
    z-index: 1;
    }
  
  .boxMain{
    position: relative;
  }
  
  .boxMain{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
  
  .figureSection{ 
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .boxCon{
    width: 60%;
    }
  
  .nftT{
    color: #4cadc5;
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
    margin-bottom: 8%;
  
  }
  
  .box1NFT{
    width: 40%;
    margin-left: 57%;
      z-index: 10000000000000000;
    position: absolute;
    margin-top: -10%;
  }
  
  .box1NFTDiv{
    margin-right: auto;
   }
  
  .nftT2{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    color: #ac64a5;
    margin-bottom: 8%;
  }
  
  .headerAndCon{
    background-color: #0038f9;
    width: fit-content;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .back{
    width: 50px;
    height: 50px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    transition: transform .2s;
    cursor: pointer;
  }
.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 
}

.glitch {
  color: rgb(255, 255, 255);
  font-size: 100px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #1ec5fb;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #ba2e79;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-o-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-moz-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-o-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-moz-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}
  /* Animation */
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  
.in2 {
  width: 100%;
  display: flex;
   justify-content: center;
  flex-flow: column nowrap;
  font-size: 80px;
  color: white;
  font-weight: bold;
  letter-spacing: 3px;
 
}


  .line-1 {
    position: relative;
     color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
      border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
     justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 690px;
    }
  }

.blinkBox{
   margin-top: 20%;
   margin-left: 5%;
   z-index: 10000000;
   line-height: 100px;
  }

  .box1NFTMob{
    display: none;
   }
   .mainNFTMob{
    width: 50%;
    position: absolute;
    margin-left: 50%;
  }
.mintBtn:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.connectBtn:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
  color: rgb(0, 0, 0);
}
.teamCon2{
  display: none;
}

#container {
  width: 70vw;
  /* margin-left: 30vw auto;
  margin-right: 30vw auto;
  margin-bottom: 50vw auto; */
  margin: 5vw auto;

}


h1:nth-child(2) {
  color: #67cbf3;
  animation: glitch2 2.5s infinite;
}

h1:nth-child(3) {
  color: #e2f2ff;
  animation: glitch3 2.5s infinite;
}

/*Keyframes*/

@keyframes glitch1 {
  0% {
    transform: none;
    opacity: 1;
  }

  7% {
    transform: skew(-0.5deg, -0.9deg);
    opacity: 0.75;
  }

  10% {
    transform: none;
    opacity: 1;
  }

  27% {
    transform: none;
    opacity: 1;
  }

  30% {
    transform: skew(0.8deg, -0.1deg);
    opacity: 0.75;
  }

  35% {
    transform: none;
    opacity: 1;
  }

  52% {
    transform: none;
    opacity: 1;
  }

  55% {
    transform: skew(-1deg, 0.2deg);
    opacity: 0.75;
  }

  50% {
    transform: none;
    opacity: 1;
  }

  72% {
    transform: none;
    opacity: 1;
  }

  75% {
    transform: skew(0.4deg, 1deg);
    opacity: 0.75;
  }

  80% {
    transform: none;
    opacity: 1;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes glitch2 {
  0% {
    transform: none;
    opacity: 0.25;
  }

  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }

  10% {
    transform: none;
    opacity: 0.25;
  }

  27% {
    transform: none;
    opacity: 0.25;
  }

  30% {
    transform: translate(-5px, -2px);
    opacity: 0.5;
  }

  35% {
    transform: none;
    opacity: 0.25;
  }

  52% {
    transform: none;
    opacity: 0.25;
  }

  55% {
    transform: translate(-5px, -1px);
    opacity: 0.5;
  }

  50% {
    transform: none;
    opacity: 0.25;
  }

  72% {
    transform: none;
    opacity: 0.25;
  }

  75% {
    transform: translate(-2px, -6px);
    opacity: 0.5;
  }

  80% {
    transform: none;
    opacity: 0.25;
  }

  100% {
    transform: none;
    opacity: 0.25;
  }
}

@keyframes glitch3 {
  0% {
    transform: none;
    opacity: 0.25;
  }

  7% {
    transform: translate(2px, 3px);
    opacity: 0.5;
  }

  10% {
    transform: none;
    opacity: 0.25;
  }

  27% {
    transform: none;
    opacity: 0.25;
  }

  30% {
    transform: translate(5px, 2px);
    opacity: 0.5;
  }

  35% {
    transform: none;
    opacity: 0.25;
  }

  52% {
    transform: none;
    opacity: 0.25;
  }

  55% {
    transform: translate(5px, 1px);
    opacity: 0.5;
  }

  50% {
    transform: none;
    opacity: 0.25;
  }

  72% {
    transform: none;
    opacity: 0.25;
  }

  75% {
    transform: translate(2px, 6px);
    opacity: 0.5;
  }

  80% {
    transform: none;
    opacity: 0.25;
  }

  100% {
    transform: none;
    opacity: 0.25;
  }
}

input {
  width: 200px;
  height: fit-content;
  text-align: center;
  background: rgba(0, 0, 0, 0.575);
  border: 2px #ffffff solid;
}

.miniBarMain {
  display: none;
}

.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Archivo Black', sans-serif;

}

h1 {
  text-align: center;
  color: #fff;
  font-size: 60px;
  /*letter-spacing: 8px;
    font-family: "Press Start 2P";
    font-weight: 400;*/
  /*Create overlap*/
  font-family: 'Archivo Black', sans-serif;

  margin: 0;
  line-height: 0;
  /*Animation*/

  animation: glitch1 2.5s infinite;
}

.littleNav {
  display: none;
}

.navbarMain {
  display: none;
}

.tokenHSub {
  color: #d1dff8;
  font-weight: 500;
  font-size: 20px;
}

.tokenH2 {
  font-size: 16px;
}

.mintBtn {
  background-color: #3aca75;
  color: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 7px;
  border: none;

}

.connectBtn {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 150px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
}

.connectBtn2 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 18px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 230px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
}


.osPic2 {
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  border-radius: 100%;
  margin-left: 15px;
  margin-right: 15px;
  transition: transform .2s;

}

.mintBox1 img{
  width: 60%;  
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.mintBox1{
  flex-flow: column nowrap;
  display: flex;

}

.mintBox{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  justify-content: space-between;
  width: 50%;
  margin-top: 10%;
  margin-bottom: 10%;
 }


.connectBtn3 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 200px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  transition: transform .2s;
}

.mintCon{
  text-align: center;
  color: rgba(255, 255, 255, 0.699);
  font-weight: bold;
  margin-top: 6%;
  margin-bottom: 6%;
}

.mintCon2{
  text-align: left;
  color: white;
 font-weight: bold; 
 margin-left: auto;
 margin-right: auto;
 color: rgba(255, 255, 255, 0.815);

}
.listSection{
  margin-left: auto;
  margin-right: auto;
}
.rmMobile {
  display: none;
}

.topLogo {
  display: none;
}

.special:hover {
  background-color: rgb(228, 194, 0);

}

.special:active {
  background-color: rgb(228, 194, 0);
  box-shadow: -0px 7px rgb(153, 131, 4);
  transform: translateY(4px);
}

.special2:hover {
  background-color: rgb(228, 194, 0);

}

.special2:active {
  background-color: rgb(228, 194, 0);
  box-shadow: -0px 7px rgb(153, 131, 4);
  transform: translateY(4px);
}

.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 50px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
}

.special {
  font-size: 20px;
  margin-left: 30px;
  border-radius: 10px;
  background-color: gold;
  border: none;
  padding: 10px;
  box-shadow: -0px 5px rgb(194, 165, 5);
}

.special2 {
  font-size: 20px;
  border-radius: 10px;
  background-color: gold;
  border: none;
  padding: 10px;
  box-shadow: -0px 5px rgb(194, 165, 5);
}

.mintingAmount {
  border: black 2px solid;
  border-radius: 10px;
  box-shadow: -0px 5px black;
  padding: 10px;
}

.connect2Mobile {
  display: none;
}

.discordNone {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: no-drop;
}

.discordNone:hover {
  transform: scale(1.1);
}

.linkedin {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  cursor: pointer;

}

.team1 {
  width: 50%;
}

.discord3 {
  display: none;
}

.discord4 {
  display: none;
}

.storyHRmM {
  display: none;
}

.iconsMob {
  display: none;
}

.mainBanner {
  display: none;
}

.icons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
 
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.discordF2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}

a {
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: white;
}

.osPic {
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  border-radius: 100%;
 }

.price {
  text-align: center;
  font-size: 22px;
  color: #acf9ff;
  font-weight: bold;
}

.anim-typewriter {
  animation: typewriter 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, .75);
  }

  to {
    border-right-color: transparent;
  }
}

.line-2 {
  position: relative;
  top: 50%;
  width: 48%;
  text-align: center;
  font-size: 20px;
  color: #acf9ff;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  border-right: 2px solid rgba(255, 255, 255, .75);
  margin-left: auto;
  margin-right: auto;
}

.anim-typewriter2 {
  animation: typewriter2 4s steps(44) 1s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter2 {
  from {
    width: 0;
  }

  to {
    width: 260px;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, .75);
  }

  to {
    border-right-color: transparent;
  }
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {
  background-color: #0b0c11;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  contain: content;
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;

  padding-top: 100px;
  padding-bottom: 20px;
  position: relative;

}

.boxWrap2Mint {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100475218662789181/NFTHouse9_blockchain_network_background_image_cyber_punk_theme__3ce5a6b3-d55d-4562-94e6-ed143a568181.png?width=725&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 20px;
}

.boxWrap2Story {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
}

.boxWrap2Story {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
 
}

.perks {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #040d1a;
}

.boxWrap2NFT {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: linear-gradient(0deg, rgba(4,13,26,1) 0%, rgba(4,13,26,0) 47%, rgba(11,12,17,0.8239670868347339) 100%), url('./assets/nft.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 2%;
  padding-bottom: 2%;
}

.videoContainer {
  padding-top: 2%;
  padding-bottom: 2%;
}

.boxWrap2Token {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100459043526561934/NFTHouse9_doge_coin_cyber_punk_theme_64k_6dc18e25-ad22-487f-9d98-6eada8a42fd1.png?width=725&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 20px;
}

.boxWrap2Team {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: rgb(0, 0, 0);

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: #040d1a;

}

.footer {
  padding-bottom: 5%;
  padding-top: 5%;
  display: flex;  
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  }

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 10px;
}

.aboutMain {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  width: 90%;
  margin-top: auto;
  margin-bottom: auto;
 }


.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
  flex-flow: row nowrap;
  width: 70%;
  margin-top: 20px;
}

.about2 img {
  width: 330px;
  height: 330px;
  border-radius: 50px;
  margin-right: 80px;
  border: 2px rgb(35, 189, 250) solid;
  margin-top: auto;
  margin-bottom: auto;
}

.tokenM {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  width: 70%;
  margin-bottom: 100px;
  /* From https://css.glass */
  background: rgb(242, 205, 68);
  background: linear-gradient(40deg, rgba(242, 205, 68, 0.3345623230666257) 0%, rgba(40, 27, 47, 0) 35%, rgba(18, 243, 251, 0.269955581493592) 100%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(12, 193, 248);
  margin-top: 20px;
  box-shadow: 0 0 15px #64dafd;
}

.tokenM img {
  width: 35%;
  height: 35%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.storyConT {
  color: white;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.about img {
  width: 50%;
  height: 50%;
  margin-top: auto;
  margin-bottom: auto;
}

.video {
  width: 150vh;
  height: 84.375vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 10px;
  border: 1px solid white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: transform .5s;
}

.aboutImg2:hover {
  transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}
.storyH2New{
  display: none;
}
.storyH {
  display: flex;
  font-size: 60px;
  color: white;
  font-weight: bold;
   text-align: center;
   margin-left: auto;
 }

.teamH {
  display: flex;
  font-size: 60px;
  color: white;
  font-weight: bold;
  margin-right: auto;
}

.storyH2 {
  display: flex;
  font-size: 60px;
  color: white;
  font-weight: bold;
  margin-right: auto;
}

.storyH3 {
  display: flex;
  font-size: 60px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.storyH6 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 60px;
  color: white;
  background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
  border: 3px solid white;
  border-bottom: 2px rgba(255, 255, 255, 0);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 600px;
  font-family: 'Archivo Black', sans-serif;

}

.storyH4 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 60px;
  color: white;
  border-radius: 20px;
  width: 600px;
  font-family: 'Archivo Black', sans-serif;
  background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
  border: 3px solid white;
}

.mintH {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 60px;
  color: white;
  border-bottom: 2px rgba(255, 255, 255, 0);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 600px;
  font-family: 'Archivo Black', sans-serif;
  background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
  border: 3px solid white;
  border-bottom: #00000000;
}

.storyRm img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.tokenH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 80px;
  color: white;

  text-align: center;
  background-color: #fe8a00;
  padding-left: 20px;
  margin-bottom: 20px;
}

.rmH2 {
  color: grey;
  font-size: 18px;
  line-height: 30px;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}



.storyCon {
  color: white;
  text-align: right;
   margin-top: auto;
  margin-bottom: auto;
   }

.perksMain{
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.storyCon-2 {
  color: white;
  font-size: 20px;
  text-align: left;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 50px;
  box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
  background-color: rgba(30, 46, 80, 0.568);

}

.utilCon {
  color: white;
  font-size: 20px;
  text-align: left;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 18px;
  text-align: left;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
}

.storyCon3 {
  font-size: 18px;
  text-align: right;
  color: white;
  margin-top: 25px;
  margin-bottom: 25px;
}


.teamCon {
  font-size: 18px;
  text-align: left;
  color: white;
  margin-top: 25px;
  margin-bottom: 25px;
}


.perksCon {
  font-size: 18px;
  text-align: center;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.storyCon3Hl {
  font-size: 18px;
  text-align: center;
  color: white;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
}

.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 100px;
  text-align: center;
}

.logoF {
  display: flex;
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border-radius: 100%;
}


.logoF img {
  width: 50%;
}

.intro img {
  width: 300px;
  height: 300px;
}

.intro3 {
  font-style: italic;
  font-size: 20px;
  font-weight: 500;
}

.intro2 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 50%;
  padding: 10px;
  border-radius: 50px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  margin-bottom: 80px;
  border: 3px solid white;
  border-radius: 20px;
  margin-top: 20px;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 20px;
  text-shadow: -2.5px 0 red;
  letter-spacing: 2px;
  text-align: center;
  color: white;
}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 22px;
  text-align: center;
  color: white;
  text-shadow: -2px 0 #7700ff;
  letter-spacing: 2px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.discord:hover {
  transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;

  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  font-family: 'Archivo Black', sans-serif;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.connect div {
  margin-left: 15px;
  margin-right: 15px;
  font-family: 'Archivo Black', sans-serif;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease;
  color: white;
}

#fontSize:hover {
  color: #13fafa;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 3%;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 100px;
  z-index: 100000000;

  cursor: pointer;
  background-color: #3a9ae800;
  color: #1cb5f1;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  border: white 2px solid;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 100px;
  z-index: 100000000;

  cursor: pointer;
  background-color: #3a9ae800;
  color: #1cb5f1;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  border: white 2px solid;

}


.btnfos-0-2:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
  color: white;
  border: #1cb5f1 2px solid;

}

.btnfos-0-2-2:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);

}


.btnfos-0-3 {
  margin-top: 20px;
  background-color: #6cfc1900;
  color: #1cb5f1;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  width: 250px;
  transition: transform .2s;
  border: none;
  z-index: 100000000;
  transition: transform .2s;
  font-weight: bold;
  border: 2px solid white;
}

.btnfos-0-3:hover {
  border: 2px solid #1cb5f1;
  color: white;
}

.nftamount {
  color: #ffffff;
  font-size: 65px;
  font-family: 'Archivo Black', sans-serif;
  text-align: center;
  -webkit-text-stroke: 2px #21bcfa;
  margin-top: 1%;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: white;

  font-size: 28px;
  text-shadow: -3px 0 #1ee03e;
  letter-spacing: 2px;
}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #000000c2;

  z-index: 1000000;
  padding-top: 30px;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.headers2 {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
 
  z-index: 1000000;
  padding-top: 30px;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 95%;
  margin-top: -13%;
  position: relative;
  margin-bottom: 20%;
}


.wallet2 {
  z-index: 1000;
  background-color: #000000;
  color: #ffffff;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 50px;
  width: 270px;
  transition: transform .2s;
  border: none;
  font-family: "Gordita";
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;
  cursor: pointer;
  box-shadow: 0 3px #363636;
  letter-spacing: 2px;
}

.wallet2:hover {
  color: #000000;
  background-color: #ffffff;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;

  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.mainNFT {
  width: 100%;
  margin-left: 3%;
  display: flex;
  justify-content: center;
  display:None;
}

.totalSupply {
  text-align: center;
  font-size: 70px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-family: 'Archivo Black', sans-serif;

  -webkit-text-stroke: 1px #a0dbfd;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 16px;
  text-shadow: 0 0 10px #FFFFFF;
  margin-top: 18px;
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 140px;
  height: 168px;
}

.logo2 {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

#fontSize {
  font-size: 17px;
  cursor: pointer;
  z-index: 10000;
  color: #fafafa;
}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/


summary {
  font-size: 21px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: #ffffff;
  padding: 0.5rem;
  margin-bottom: 2.5rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border-bottom: #8b8b8b 2px solid;
  font-weight: bold;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: rgb(255, 252, 252);
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  background-color: #000000ab;
  border-radius: 20px;
  padding: 50px;
  border: 3px white solid;
}

.faq__content {

  color: #ffffff;
  padding-bottom: 20px;
  text-align: left;
  font-size: 20px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;

}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 100px;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 70%;
  border-radius: 30px;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
}

.year {
  font-size: 18px;

  color: rgb(8, 81, 94);
  letter-spacing: 1px;
}


.rmBoxMain2 {
  margin-top: 30%;
  width: 33.33%;
}

.rmBoxMain {
  width: max-content;
  justify-content: flex-start;
  width: 33.33%;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 130px;
  height: 186px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 25%;
  margin-right: auto;
  display: block;
  margin-left: 33%;
  margin-top: -50%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 270vh;
  margin-top: -30%;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 33.33%;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {
  background-color: #0b0c11;
  height: fit-content;
}

.web {
  width: 100%;
  height: 100%;
}

.cont .drip {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgb(24, 70, 28);
  position: absolute;
  top: -100%;
  animation: falling 5s linear infinite;
}

@keyframes falling {
  0% {
    top: -100%;
  }

  50% {
    top: 0%;
  }

  80% {
    top: 80%;
  }

  100% {
    top: 100%;
  }
}

.cont .drip:nth-child(1) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 241px;
  margin-left: 60px;
}

.cont .drip:nth-child(2) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 175px;
  margin-left: 60px;
}

.cont .drip:nth-child(3) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 190px;
  margin-left: 60px;
}

.cont .drip:nth-child(4) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 86px;
  margin-left: 60px;
}

.cont .drip:nth-child(5) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 197px;
  margin-left: 60px;
}

.cont .drip:nth-child(6) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 244px;
  margin-left: 60px;
}

.cont .drip:nth-child(7) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(8) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(9) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 29px;
  margin-left: 60px;
}

.cont .drip:nth-child(10) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 154px;
  margin-left: 60px;
}

.cont .drip:nth-child(11) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 109px;
  margin-left: 60px;
}

.cont .drip:nth-child(12) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 272px;
  margin-left: 60px;
}

.cont .drip:nth-child(13) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(14) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(15) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 95%;
  margin-left: 60px;
}

.cont .drip:nth-child(16) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 76%;
  margin-left: 60px;
}

.cont .drip:nth-child(17) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(18) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 46%;
  margin-left: 60px;
}

.cont .drip:nth-child(19) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(20) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 33%;
  margin-left: 60px;
}


.cont .drip:nth-child(21) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 42%;
  margin-left: 60px;
}

.cont .drip:nth-child(22) {
  border-color: black;
  height: 9px;
  width: 9px;
  animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(23) {
  border-color: black;
  height: 36px;
  width: 36px;
  animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 49%;
  margin-left: 60px;
}

.cont .drip:nth-child(24) {
  border-color: black;
  height: 24px;
  width: 24px;
  animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 73%;
  margin-left: 60px;
}

.cont .drip:nth-child(25) {
  border-color: black;
  height: 5px;
  width: 5px;
  animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 83%;
  margin-left: 60px;
}

.cont .drip:nth-child(26) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 28%;
  margin-left: 60px;
}

.cont .drip:nth-child(27) {
  border-color: black;
  height: 11px;
  width: 11px;
  animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 14%;
  margin-left: 60px;
}

.cont .drip:nth-child(28) {
  border-color: black;
  height: 10px;
  width: 10px;
  animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 43%;
  margin-left: 60px;
}

.cont .drip:nth-child(29) {
  border-color: black;
  height: 27px;
  width: 27px;
  animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 68%;
  margin-left: 60px;
}

.cont .drip:nth-child(30) {
  border-color: black;
  height: 33px;
  width: 33px;
  animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 57%;
  margin-left: 60px;
}

.cont .drip:nth-child(31) {
  border-color: black;
  height: 39px;
  width: 39px;
  animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 27%;
  margin-left: 60px;
}

.cont .drip:nth-child(32) {
  border-color: black;
  height: 23px;
  width: 23px;
  animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 12%;
  margin-left: 60px;
}

.cont .drip:nth-child(33) {
  border-color: black;
  height: 13px;
  width: 13px;
  animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 30%;
  margin-left: 60px;
}

.cont .drip:nth-child(34) {
  border-color: black;
  height: 21px;
  width: 21px;
  animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 38%;
  margin-left: 60px;
}

.cont .drip:nth-child(35) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 55%;
  margin-left: 60px;
}

.cont .drip:nth-child(36) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 50%;
  margin-left: 60px;
}

.cont .drip:nth-child(37) {
  border-color: black;
  height: 12px;
  width: 12px;
  animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(38) {
  border-color: black;
  height: 38px;
  width: 38px;
  animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 67%;
  margin-left: 60px;
}

.cont .drip:nth-child(39) {
  border-color: black;
  height: 1px;
  width: 1px;
  animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 70%;
  margin-left: 60px;
}

.cont .drip:nth-child(40) {
  border-color: black;
  height: 19px;
  width: 19px;
  animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 90%;
  margin-left: 60px;
}

.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  width: 90%;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;

}

.teamCon img {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border: 3px solid white;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 23px;
  text-align: center;
}

.memberCon {
  font-size: 20px;
  text-align: center;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 6rem;
  
  padding-left: 100%;
  background-size: contain;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 6rem;
  line-height: 6rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 20s;
  animation-duration: 70s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 25px;
  color: #ffffff;
  text-shadow: 0 0 10px #5bbcfc;
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: 2px;
}

.shark {
  width: 400px;
  height: 572px;
  z-index: 1;
  margin-right: -3%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.teamMain {
  display: flex;
}


.memName {

  color: #fe8f00;
  font-size: 23px;
  text-align: center;
  padding-right: 2%;

}

.memNamePosition {
  color: rgb(0, 0, 0);
  font-size: 17px;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.tPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
}


.man {
  width: 350px;
  height: 350px;
}

.teamSection {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.tPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.subT {

  color: #f9f305;
  font-size: 50px;
  text-align: left;
}

.manDiv {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.memName2 {
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 500;
  text-shadow: 0 0 10px rgb(255, 255, 255);
}

.rmDetails p {
  color: rgb(209, 209, 209);
  padding-left: 20px;
  font-size: 45px;
}

.flowers {
  width: 100%;
  margin-top: -10%;
}

.rmPicAndCon {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.footPrint2 {
  width: 60px;
  height: 60px;
}


/* Background */
.mainFoot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: -10%;
}

/* Footprint */
.footprint {
  position: absolute;
  top: 20%;
  left: 0;
  animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
  text-align: center;
}

/* Image Size */
.foot {
  max-width: 20%;
}

/* Right Footprint */
.rightFoot {
  margin: 5rem;
  animation-delay: 250ms;
}

/* Walking Animation */
@keyframes walk {
  to {
    transform: translateX(100vw);
  }
}

/* Opacity During Walking */
@keyframes walkopacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }
}

.honeyComb {
  width: 450px;
  height: 345px;
  position: sticky;
  position: -webkit-sticky;
  left: 80%;
  bottom: 80px;
}

.dripCover {
  width: 100%;
}

.ball {
  width: 100px;
  height: 100px;
  animation: floating 2s ease-in-out infinite;
  margin-top: -200px;
  margin-left: 200px;
}

.ball4 {
  width: 130px;
  height: 130px;
  animation: floating4 2.1s ease-in-out infinite;
  margin-top: -200px;
  margin-left: 250px;
}

.ball5 {
  width: 130px;
  height: 130px;
  animation: floating4 2.3s ease-in-out infinite;
  margin-top: -400px;
  margin-left: 200px;
}

.ball6 {
  width: 130px;
  height: 130px;
  animation: floating4 2.8s ease-in-out infinite;
  margin-top: -1100px;
  margin-right: 600px;
}

.ball7 {
  width: 130px;
  height: 130px;
  animation: floating4 3s ease-in-out infinite;
  margin-top: -1000px;
  margin-left: 1600px;
}

/* Define the keyframes for the floating animation */
@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes floating2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes floating3 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes floating4 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

/* CSS */
.button-52 {
  font-size: 18px;
  font-family: 'Archivo Black', sans-serif;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid rgb(255, 255, 255);
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 250px;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
}

.button-52:after {
  content: "";
  background-color: #f37539;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.button-52:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}


.container img {
  width: 45%;
  height: 100%;
  object-fit: cover;
  -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  transform-origin: center;
  transform: perspective(800px) rotateY(25deg);
  transition: 0.5s;
}

.container {
  max-width: 850px;
  max-height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 100px;
  margin-bottom: 100px;

}

.container:hover img {
  opacity: 0.3;
}

.container img:hover {
  transform: perspective(800px) rotateY(0deg);
  opacity: 1;
}

/* Define the animation */
@keyframes bounce {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}

/* Apply the animation to an element */
#ball {
  width: 270px;
  height: 270px;
  animation: bounce 1.5s infinite;
  position: sticky;
  position: -webkit-sticky;
  left: 100%;
  bottom: 0px;
}

#ball3 {
  width: 270px;
  height: 270px;
  animation: bounce 1.5s infinite;
  position: sticky;
  position: -webkit-sticky;
  left: 5%;
  bottom: 0px;
}

.ball2 {
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  /* Animation to spin and move the sphere */
  -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  position: absolute;
  left: 0;
}

.ballDiv {
  position: relative;
  height: 300px;
  padding-top: 30px;
}

/* Spinning the sphere using key frames */
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

/* Move sphere from left to right */
@-moz-keyframes moveLeftToRight {
  0% {
    left: -100px;
  }

  100% {
    left: 100%;
  }
}

@-ms-keyframes moveLeftToRight {
  0% {
    left: -100px;
  }

  100% {
    left: 100%;
  }
}

@keyframes moveLeftToRight {
  0% {
    left: -100px;
  }

  100% {
    left: 100%;
  }
}

@-webkit-keyframes moveLeftToRight {
  0% {
    left: -100px;
  }

  100% {
    left: 100%;
  }
}

@keyframes football-rolling {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


:root {
  --color1: #55476A;
  --color2: #AE3D63;
  --color3: #DB3853;
  --color4: #F45C44;
  --color5: #F8B663;
}

.container2 {
  min-height: 100vh;
  overflow: hidden;
  padding: 60px;
  position: relative;
  width: 100%;
}

.confetti {
  position: absolute;
  display: flex;
  width: 10px;
  height: 25px;
  top: -100px;
}

.confetti:nth-child(1) {
  background-color: var(--color1);
  animation: fall 2.5s linear infinite;
  left: 10%;
}

.confetti:nth-child(2) {
  background-color: var(--color2);
  animation: fall 2.3s linear infinite .2s;
  left: 20%;
}

.confetti:nth-child(3) {
  background-color: var(--color3);
  animation: fall 2.4s linear infinite .4s;
  left: 30%;
}

.confetti:nth-child(4) {
  background-color: var(--color4);
  animation: fall 2.7s linear infinite .1s;
  left: 40%;
}

.confetti:nth-child(5) {
  background-color: var(--color5);
  animation: fall 2.6s linear infinite .7s;
  left: 50%;
}

.confetti:nth-child(6) {
  background-color: var(--color1);
  animation: fall 2.4s linear infinite .2s;
  left: 60%;
}

.confetti:nth-child(7) {
  background-color: var(--color2);
  animation: fall 2.1s linear infinite .7s;
  left: 70%;
}

.confetti:nth-child(8) {
  background-color: var(--color3);
  animation: fall 2.4s linear infinite .9s;
  left: 80%;
}

.confetti:nth-child(9) {
  background-color: var(--color4);
  animation: fall 2.9s linear infinite .9s;
  left: 90%;
}

.confetti:nth-child(10) {
  background-color: var(--color5);
  animation: fall 2.2s linear infinite 1.1s;
  left: 100%;
}

.confetti:nth-child(11) {
  background-color: var(--color1);
  animation: fall2 2.5s linear infinite;
  left: 95%;
}

.confetti:nth-child(12) {
  background-color: var(--color2);
  animation: fall2 2.3s linear infinite 1.1s;
  left: 85%;
}

.confetti:nth-child(13) {
  background-color: var(--color3);
  animation: fall2 2.4s linear infinite 1.2s;
  left: 75%;
}

.confetti:nth-child(14) {
  background-color: var(--color4);
  animation: fall2 2.7s linear infinite 1.3s;
  left: 65%;
}

.confetti:nth-child(15) {
  background-color: var(--color5);
  animation: fall2 2.6s linear infinite 1.4s;
  left: 55%;
}

.confetti:nth-child(16) {
  background-color: var(--color1);
  animation: fall2 2.4s linear infinite 1.5s;
  left: 45%;
}

.confetti:nth-child(17) {
  background-color: var(--color2);
  animation: fall2 2.1s linear infinite 1.6s;
  left: 35%;
}

.confetti:nth-child(18) {
  background-color: var(--color3);
  animation: fall2 2.4s linear infinite 1.7s;
  left: 25%;
}

.confetti:nth-child(19) {
  background-color: var(--color4);
  animation: fall2 2.9s linear infinite 1.8s;
  left: 15%;
}

.confetti:nth-child(20) {
  background-color: var(--color5);
  animation: fall2 2.2s linear infinite 1.9s;
  left: 5%;
}

.confetti:nth-child(21) {
  background-color: var(--color1);
  animation: fall 2.5s linear infinite;
  left: 3%;
}

.confetti:nth-child(22) {
  background-color: var(--color2);
  animation: fall 2.3s linear infinite 1.3s;
  left: 13%;
}

.confetti:nth-child(23) {
  background-color: var(--color3);
  animation: fall 2.4s linear infinite 1.4s;
  left: 23%;
}

.confetti:nth-child(24) {
  background-color: var(--color4);
  animation: fall 2.7s linear infinite 1.5s;
  left: 33%;
}

.confetti:nth-child(25) {
  background-color: var(--color5);
  animation: fall 2.6s linear infinite 1.6s;
  left: 43%;
}

.confetti:nth-child(26) {
  background-color: var(--color1);
  animation: fall 2.4s linear infinite 1.2s;
  left: 53%;
}

.confetti:nth-child(27) {
  background-color: var(--color2);
  animation: fall 2.1s linear infinite 1.7s;
  left: 63%;
}

.confetti:nth-child(28) {
  background-color: var(--color3);
  animation: fall 2.4s linear infinite 1.8s;
  left: 73%;
}

.confetti:nth-child(29) {
  background-color: var(--color4);
  animation: fall 2.9s linear infinite 1.9s;
  left: 83%;
}

.confetti:nth-child(30) {
  background-color: var(--color5);
  animation: fall 2.2s linear infinite 1.1s;
  left: 93%;
}

.confetti:nth-child(31) {
  background-color: var(--color1);
  animation: fall2 2.2s linear infinite 1.1s;
  left: 7%;
}

.confetti:nth-child(32) {
  background-color: var(--color2);
  animation: fall2 2.9s linear infinite .9s;
  left: 17%;
}

.confetti:nth-child(33) {
  background-color: var(--color3);
  animation: fall2 2.4s linear infinite .1s;
  left: 27%;
}

.confetti:nth-child(34) {
  background-color: var(--color4);
  animation: fall2 2.1s linear infinite .7s;
  left: 37%;
}

.confetti:nth-child(35) {
  background-color: var(--color5);
  animation: fall2 2.4s linear infinite .2s;
  left: 47%;
}

.confetti:nth-child(36) {
  background-color: var(--color1);
  animation: fall2 2.6s linear infinite .7s;
  left: 57%;
}

.confetti:nth-child(37) {
  background-color: var(--color2);
  animation: fall2 2.7s linear infinite .9s;
  left: 67%;
}

.confetti:nth-child(38) {
  background-color: var(--color3);
  animation: fall2 2.4s linear infinite .4s;
  left: 77%;
}

.confetti:nth-child(39) {
  background-color: var(--color4);
  animation: fall2 2.3s linear infinite .2s;
  left: 87%;
}

.confetti:nth-child(40) {
  background-color: var(--color5);
  animation: fall2 2.5s linear infinite .3s;
  left: 97%;
}

@keyframes fall {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(-90deg);
    opacity: 1;
  }

  100% {
    top: 900px;
    transform: rotate(360deg) rotateY(180deg);
    opacity: 0.7;
  }
}

@keyframes fall2 {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(90deg);
    opacity: 1;
  }

  100% {
    top: 900px;
    transform: rotate(-360deg) rotateY(-180deg);
    opacity: 0.5;
  }
}

.paper {
  width: 100%;
}

.rmBg {
  position: relative;
  height: fit-content;
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.rmPic {
  width: 100%;
  position: absolute;
}

.mapBg {

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;

  height: 190vh;
}

.stadium1 {
  width: 18%;
  margin-right: 63%;
  margin-top: 11%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}


.stadium1:hover+.chatBox1 {
  cursor: pointer;
  margin-top: 4%;
  z-index: 10000;
  width: 35%;
  display: block;
  margin-right: 220px;
}

.chatBox1 {
  display: none;

}

.stadium2:hover+.chatBox2 {
  cursor: pointer;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-left: 20%;
  margin-top: 2%;
}

.chatBox2 {
  display: none;

}

.stadium3:hover+.chatBox3 {
  cursor: pointer;
  margin-top: 20%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-right: 58%;
}

.chatBox3 {
  display: none;

}

.stadium4:hover+.chatBox4 {
  cursor: pointer;
  margin-top: 36%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-left: 33%;
}

.chatBox4 {
  display: none;

}

.stadium5:hover+.chatBox5 {
  cursor: pointer;
  margin-top: 48%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-right: 38%;
}

.chatBox5 {
  display: none;

}

.stadium6:hover+.chatBox6 {
  cursor: pointer;
  margin-top: 60%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-left: 60%;
}

.chatBox6 {
  display: none;

}


.ball1-rm {
  width: 6%;
  margin-right: 26%;
  margin-top: 4%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 1.1s infinite;
}

.ball5-rm {
  width: 6%;
  margin-left: 65%;
  margin-top: 22%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 1.2s infinite;

}

@keyframes bounce2 {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-70px);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}

.stadium2 {
  width: 18%;
  margin-left: 22%;
  margin-top: 22%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.stadium3 {
  width: 18%;
  margin-right: 57%;
  margin-top: 37%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball3-rm {
  width: 5%;
  margin-left: 72%;
  margin-top: 45%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 2s infinite;

}


.ball4-rm {
  width: 6%;
  margin-left: 72%;
  margin-top: 73%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball5-rm {
  width: 5%;
  margin-left: 15%;
  margin-top: 73%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 1s infinite;

}

.ball6-rm {
  width: 4%;
  margin-right: 70%;
  margin-top: 56%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball9-rm {
  width: 4%;
  margin-right: -65%;
  margin-top: 5%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball7-rm {
  width: 5%;
  margin-left: 10%;
  margin-top: 28%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball8-rm {
  width: 4%;
  margin-left: 65%;
  margin-top: 18%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 1.8s infinite;

}

.stadium4 {
  width: 18%;
  margin-left: 33%;
  margin-top: 55%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.stadium5 {
  width: 18%;
  margin-right: 36%;
  margin-top: 68%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.stadium6 {
  width: 18%;
  margin-left: 55%;
  margin-top: 80%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.cup1 {
  width: 10%;
  margin-right: 70%;
  margin-top: 76%;
  position: absolute;
  z-index: 10000;
}

.shiny {
  box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
  /* Add a transition effect to the element */
  transition: box-shadow 0.5s;
  animation: glowing 1s infinite;
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  z-index: 1000;
  margin-right: 67%;
  margin-top: 77%;
}

.shiny2 {
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
  /* Add a transition effect to the element */
  transition: box-shadow 0.5s;
  animation: glowing 1s infinite;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  z-index: 1000;
  margin-right: 67%;
  margin-top: 77%;
}

.shiny3 {
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
  /* Add a transition effect to the element */
  transition: box-shadow 0.5s;
  animation: glowing 1s infinite;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  z-index: 100;
  margin-left: 27.5%;
  margin-top: 5%;
}

/* CSS */
.button-51 {
  background-color: transparent;
  border: 1px solid #266DB6;
  box-sizing: border-box;
  color: #00132C;
  font-family: "Avenir Next LT W01 Bold", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 23px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-51:hover,
.button-51:active {
  outline: 0;
}

.button-51:hover {
  background-color: transparent;
  cursor: pointer;
}

.button-51:before {
  background-color: #D5EDF6;
  content: "";
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.button-51:hover:before {
  background-color: #6DCFF6;
}

@media (min-width: 768px) {
  .button-51 {
    padding: 16px 32px;
  }
}

@keyframes glowing {

  /* Start the animation at 50% */
  50% {
    /* Increase the size and intensity of the shiny effect */
    box-shadow: 0 0 160px 5px rgb(255, 176, 4);
  }

  /* End the animation at 100% */
  100% {
    /* Return the element to its original state */
    box-shadow: 0 0 160px 2px rgb(255, 255, 255);
  }
}

.cup2 {
  width: 12%;
  margin-right: -21%;
  margin-top: 4%;
  position: absolute;
  z-index: 1000;
}

.partners {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 50px;
}

.partnerDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 70%;
  display: flex;
}

.partnerDiv img {
  width: 220px;
  height: 220px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: -80px;
  opacity: 0.6;
  transition: 0.3s;
  cursor: pointer;
}

.partnerDiv img:hover {
  opacity: 1
}

/* CSS */
.button-78 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: 'Archivo Black', sans-serif;
  font-size: 30px;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
  width: 200px;
  letter-spacing: 3px;
  animation: floating 2s ease-in-out infinite;

}

.stickyBtn {
  position: sticky;
  bottom: -7%;
  margin-left: -5%;
  margin-top: -30%;
  width: 400px;
  height: 400px;
  cursor: pointer;
  z-index: 1;
  animation: floating 2s ease-in-out infinite;
  z-index: 100000;
}

.stickyBtn2 {

  width: 65px;
  height: 65px;

}

#sticky-button {
  position: sticky;
  visibility: hidden;
  position: sticky;
  bottom: 23%;
  margin-left: 90%;
  margin-top: -5%;

  cursor: pointer;
  z-index: 1;
  z-index: 100000;
  transition: transform .4s;
}

.stickyBtn2:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.row1{
  display: flex;
  flex-flow: column nowrap;
  color: white;
  justify-content: space-between;
  }

.coloumn1{
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}  

.figure1{
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.figure2{
  text-align: center;
}

.box1{
  display: flex;
  flex-flow: column nowrap;
/* From https://css.glass */
background: rgba(255, 255, 255, 0.116);
border-radius: 16px;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
   margin-top: 20%;
  margin-bottom: 20%;
   margin-left: auto;
  margin-right: auto;
  padding: 20px;
  z-index: 1;
 }

.boxMain{
  position: relative;
}

.boxMain{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

.figureSection{ 
  margin-top: 5%;
  margin-bottom: 5%;
}

.boxCon{
  width: 60%;
  }

.nftT{
  color: #4cadc5;
  font-weight: bolder;
  font-size: 20px;
  text-align: center;
  margin-bottom: 8%;

}

.box1NFT{
  width: 35%;
  margin-left: 57%;
    z-index: 10000000000000000;
  position: absolute;
  margin-top: -9%;
}

.box1NFTDiv{
  margin-right: auto;
 }

.nftT2{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #ac64a5;
  margin-bottom: 8%;
}

.headerAndCon{
  background-color: #0038f9;
  width: fit-content;
}
}

@media screen and (min-width: 1920px) {
  .back{
    width: 80px;
    height: 80px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .2s;
  }
.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 
}

.glitch {
  color: rgb(255, 255, 255);
  font-size: 170px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 4px;
  text-shadow: -4px 0 #1ec5fb;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -4px;
  text-shadow: -4px 0 #ba2e79;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-o-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-moz-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-o-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-moz-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}
.connectBtn2 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 22px;
  font-size: 26px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: bold;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 300px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
}


.osPic2 {
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 20px;
  transition: transform .2s;

}

.mintBox1 img{
  width: 60%;  
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.mintBox1{
  flex-flow: column nowrap;
  display: flex;

}

.mintBox{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  justify-content: space-between;
  width: 50%;
  margin-top: 10%;
  margin-bottom: 10%;
 }


.connectBtn3 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 250px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  transition: transform .2s;
}

.mintCon{
  text-align: center;
  color: rgba(255, 255, 255, 0.699);
  font-weight: bold;
  margin-top: 6%;
  margin-bottom: 6%;
  font-size: 22px; 

}

.mintCon2{
  text-align: left;
  color: white;
 font-weight: bold; 
 margin-left: auto;
 margin-right: auto;
 color: rgba(255, 255, 255, 0.815);
 font-size: 22px; 
}
.listSection{
  margin-left: auto;
  margin-right: auto;
}
  .box1NFTMob{
    display: none;
   }

  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    color: rgb(0, 0, 0);
  }
  .teamCon2{
    display: none;
  }
  
  #container {
    width: 70vw;
    /* margin-left: 30vw auto;
    margin-right: 30vw auto;
    margin-bottom: 50vw auto; */
    margin: 5vw auto;
  
  }
  
  
  h1:nth-child(2) {
    color: #67cbf3;
    animation: glitch2 2.5s infinite;
  }
  
  h1:nth-child(3) {
    color: #e2f2ff;
    animation: glitch3 2.5s infinite;
  }
  
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
  
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
  
    10% {
      transform: none;
      opacity: 1;
    }
  
    27% {
      transform: none;
      opacity: 1;
    }
  
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
  
    35% {
      transform: none;
      opacity: 1;
    }
  
    52% {
      transform: none;
      opacity: 1;
    }
  
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
  
    50% {
      transform: none;
      opacity: 1;
    }
  
    72% {
      transform: none;
      opacity: 1;
    }
  
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
  
    80% {
      transform: none;
      opacity: 1;
    }
  
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  input {
    width: 200px;
    height: fit-content;
    text-align: center;
    background: rgba(0, 0, 0, 0.575);
    border: 2px #ffffff solid;
  }
  
  .miniBarMain {
    display: none;
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    /*letter-spacing: 8px;
      font-family: "Press Start 2P";
      font-weight: 400;*/
    /*Create overlap*/
    font-family: 'Archivo Black', sans-serif;
  
    margin: 0;
    line-height: 0;
    /*Animation*/
  
    animation: glitch1 2.5s infinite;
  }
  
  .littleNav {
    display: none;
  }
  
  .navbarMain {
    display: none;
  }
  
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 20px;
  }
  
  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 15px;
    font-size: 28px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 220px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -10px -10px 20px;
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
   
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 85px;
    height: 85px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
    border-radius: 100%;
    margin-left: 5px;
    margin-right: 5px;
   }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    text-align: center;
    font-size: 20px;
    color: #acf9ff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 260px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: #0b0c11;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  
    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100475218662789181/NFTHouse9_blockchain_network_background_image_cyber_punk_theme__3ce5a6b3-d55d-4562-94e6-ed143a568181.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
   
  }
  
  .perks {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: #040d1a;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(4,13,26,1) 0%, rgba(4,13,26,0) 47%, rgba(11,12,17,0.8239670868347339) 100%), url('./assets/nft.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  .videoContainer {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100459043526561934/NFTHouse9_doge_coin_cyber_punk_theme_64k_6dc18e25-ad22-487f-9d98-6eada8a42fd1.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #040d1a;
  
  }
  
  .footer {
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;  
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 10px;
  }
  
  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 70%;
    margin-top: 20px;
  }
  
  .about2 img {
    width: 330px;
    height: 330px;
    border-radius: 50px;
    margin-right: 80px;
    border: 2px rgb(35, 189, 250) solid;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    margin-bottom: 100px;
    /* From https://css.glass */
    background: rgb(242, 205, 68);
    background: linear-gradient(40deg, rgba(242, 205, 68, 0.3345623230666257) 0%, rgba(40, 27, 47, 0) 35%, rgba(18, 243, 251, 0.269955581493592) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgb(12, 193, 248);
    margin-top: 20px;
    box-shadow: 0 0 15px #64dafd;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .about img {
    width: 50%;
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .video {
    width: 100vh;
    height: 56.25vh;    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
    border: 3px solid white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  .storyH2New{
    display: none;
  }
  .storyH {
    display: flex;
    font-size: 85px;
    color: white;
    font-weight: bold;
    margin-left: auto;
  }
  
  .teamH {
    display: flex;
    font-size: 85px;
    color: white;
    font-weight: bold;
    margin-right: auto;
  }
  
  .storyH2 {
    display: flex;
    font-size: 85px;
    color: white;
    font-weight: bold;
    margin-right: auto;
  }
  
  .storyH3 {
    display: flex;
    font-size: 85px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
  }
  
  .perksMain{
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 26px;
    text-align: left;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon3 {
    font-size: 26px;
    text-align: right;
    color: white;
    margin-top: 45px;
    margin-bottom: 45px;
  }
  
  
  .teamCon {
    font-size: 26px;
    text-align: left;
    color: white;
    margin-top: 45px;
    margin-bottom: 45px;
  }
  
  
  .perksCon {
    font-size: 26px;
    text-align: center;
    color: white;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  
  .storyCon3Hl {
    font-size: 26px;
    text-align: center;
    color: white;
    margin-top: 32px;
    margin-bottom: 32px;
    font-weight: bold;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 100%;
  }
  
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid white;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #7700ff;
    letter-spacing: 2px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  }
  
  #fontSize:hover {
    color: #13fafa;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc1900;
    color: #1cb5f1;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    width: 250px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    transition: transform .2s;
    font-weight: bold;
    border: 2px solid white;
  }
  
  .btnfos-0-3:hover {
    border: 2px solid #1cb5f1;
    color: white;
  }
  
  .nftamount {
    color: #ffffff;
    font-size: 65px;
    font-family: 'Archivo Black', sans-serif;
    text-align: center;
    -webkit-text-stroke: 2px #21bcfa;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 28px;
    text-shadow: -3px 0 #1ee03e;
    letter-spacing: 2px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
  
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
   
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  

  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 95%;
    margin-top: -13%;
    position: relative;
    margin-bottom: 20%;
  }
  
    /* Animation */
    .anim-typewriter {
      animation: typewriter 4s steps(44) 1s 1 normal both,
        blinkTextCursor 500ms steps(44) infinite normal;
    }
  
    
  .in2 {
    width: 100%;
    display: flex;
     justify-content: center;
    flex-flow: column nowrap;
    font-size: 110px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
   
  }
  
  
    .line-1 {
      position: relative;
       color: rgb(255, 255, 255);
      font-size: 20px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
        border-right: 4px solid rgba(255, 255, 255, .75);
      width: 100%;
      display: flex;
       justify-content: center;
      flex-flow: column nowrap;
      font-size: 110px;
      color: white;
      font-weight: bold;
      letter-spacing: 3px;
    }
    
    @keyframes typewriter {
      from {
        width: 0;
      }
    
      to {
        width: 930px;
      }
    }
  
  .blinkBox{
     margin-top: 20%;
     margin-left: 5%;
     z-index: 10000000;
     line-height: 140px;
    }
  
    .box1NFTMob{
      display: none;
     }
     .mainNFTMob{
      width: 50%;
      position: absolute;
      margin-left: 50%;
    }
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .mainNFT {
    width: 100%;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    display: none;
  }
   
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: 'Archivo Black', sans-serif;
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 190px;
    height: 228px;
  }
  
  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 25px;
    cursor: pointer;
    z-index: 10000;
    color: #fafafa;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }
  
  .faq__content {
  
    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #0b0c11;
    height: fit-content;
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 350px;
    height: 350px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }
  
  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }
  
  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }
  
  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }
  
  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container img {
    width: 45%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }
  
  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
  
  }
  
  .container:hover img {
    opacity: 0.3;
  }
  
  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPic {
    width: 100%;
    position: absolute;
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  
  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }
  
  .chatBox1 {
    display: none;
  
  }
  
  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }
  
  .chatBox2 {
    display: none;
  
  }
  
  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }
  
  .chatBox3 {
    display: none;
  
  }
  
  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }
  
  .chatBox4 {
    display: none;
  
  }
  
  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }
  
  .chatBox5 {
    display: none;
  
  }
  
  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }
  
  .chatBox6 {
    display: none;
  
  }
  
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  
  }
  
  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: 'Archivo Black', sans-serif;
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 95px;
    height: 95px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
    margin-top: -5%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .row1{
    display: flex;
    flex-flow: column nowrap;
    color: white;
    justify-content: space-between;
    }
  
  .coloumn1{
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }  
  
  .figure1{
    font-weight: bold;
    font-size: 38px;
    text-align: center;
  }
  
  .figure2{
    text-align: center;
    font-size: 22px;
  }
  
  .box1{
    display: flex;
    flex-flow: column nowrap;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.116);
  border-radius: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
     margin-top: 20%;
    margin-bottom: 20%;
     margin-left: auto;
    margin-right: auto;
    padding: 20px;
    z-index: 1;
   }
  
  .boxMain{
    position: relative;
  }
  
  .boxMain{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
  
  .figureSection{ 
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .boxCon{
    width: 60%;
    }
   
  .box1NFT{
    width: 35%;
    margin-left: 57%;
      z-index: 10000000000000000;
    position: absolute;
    margin-top: -9.8%;
  }
  
  .box1NFTDiv{
    margin-right: auto;
   }

    
  .nftT{
    color: #4cadc5;
    font-weight: bolder;
    font-size: 30px;
    text-align: center;
    margin-bottom: 8%;  
  }
  
  .nftT2{
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    color: #ac64a5;
    margin-bottom: 8%;
  }
  
  .headerAndCon{
    background-color: #0038f9;
    width: fit-content;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .back{
    width: 110px;
    height: 110px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .2s;
  }   
.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 
}

.glitch {
  color: rgb(255, 255, 255);
  font-size: 200px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 6px;
  text-shadow: -6px 0 #1ec5fb;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -6px;
  text-shadow: -6px 0 #ba2e79;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-o-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-moz-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-o-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-moz-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}
.connectBtn2 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 28px;
  font-size: 33px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: bold;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 400px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
}


.osPic2 {
  width: 95px;
  height: 95px;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  border-radius: 100%;
  margin-left: 25px;
  margin-right: 25px;
  transition: transform .2s;

}

.mintBox1 img{
  width: 60%;  
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.mintBox1{
  flex-flow: column nowrap;
  display: flex;

}

.mintBox{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  justify-content: space-between;
  width: 50%;
  margin-top: 10%;
  margin-bottom: 10%;
 }


.connectBtn3 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 33px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 300px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  transition: transform .2s;
}

.mintCon{
  text-align: center;
  color: rgba(255, 255, 255, 0.699);
  font-weight: bold;
  margin-top: 6%;
  margin-bottom: 6%;
  font-size: 30px; 

}

.mintCon2{
  text-align: left;
  color: white;
 font-weight: bold; 
 margin-left: auto;
 margin-right: auto;
 color: rgba(255, 255, 255, 0.815);
 font-size: 30px; 
}
.listSection{
  margin-left: auto;
  margin-right: auto;
}
  .box1NFTMob{
    display: none;
   }
 
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    color: rgb(0, 0, 0);
  }
  
  .teamCon2{
    display: none;
  }
  #container {
    width: 70vw;
    /* margin-left: 30vw auto;
    margin-right: 30vw auto;
    margin-bottom: 50vw auto; */
    margin: 5vw auto;
  
  }
  
  
  h1:nth-child(2) {
    color: #67cbf3;
    animation: glitch2 2.5s infinite;
  }
  
  h1:nth-child(3) {
    color: #e2f2ff;
    animation: glitch3 2.5s infinite;
  }
  
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
  
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
  
    10% {
      transform: none;
      opacity: 1;
    }
  
    27% {
      transform: none;
      opacity: 1;
    }
  
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
  
    35% {
      transform: none;
      opacity: 1;
    }
  
    52% {
      transform: none;
      opacity: 1;
    }
  
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
  
    50% {
      transform: none;
      opacity: 1;
    }
  
    72% {
      transform: none;
      opacity: 1;
    }
  
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
  
    80% {
      transform: none;
      opacity: 1;
    }
  
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  input {
    width: 200px;
    height: fit-content;
    text-align: center;
    background: rgba(0, 0, 0, 0.575);
    border: 2px #ffffff solid;
  }
  
  .miniBarMain {
    display: none;
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    /*letter-spacing: 8px;
      font-family: "Press Start 2P";
      font-weight: 400;*/
    /*Create overlap*/
    font-family: 'Archivo Black', sans-serif;
  
    margin: 0;
    line-height: 0;
    /*Animation*/
  
    animation: glitch1 2.5s infinite;
  }
  
  .littleNav {
    display: none;
  }
  
  .navbarMain {
    display: none;
  }
  
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 20px;
  }
  
  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 18px;
    font-size: 40px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 280px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -10px -10px 20px;
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
   
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 110px;
    height: 110px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
    border-radius: 100%;
    margin-left: 8px;
    margin-right: 8px;
   }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    text-align: center;
    font-size: 20px;
    color: #acf9ff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 260px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: #0b0c11;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  
    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100475218662789181/NFTHouse9_blockchain_network_background_image_cyber_punk_theme__3ce5a6b3-d55d-4562-94e6-ed143a568181.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
   
  }
  
  .perks {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: #040d1a;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(4,13,26,1) 0%, rgba(4,13,26,0) 47%, rgba(11,12,17,0.8239670868347339) 100%), url('./assets/nft.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  .videoContainer {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100459043526561934/NFTHouse9_doge_coin_cyber_punk_theme_64k_6dc18e25-ad22-487f-9d98-6eada8a42fd1.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #040d1a;
  
  }
  
  .footer {
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;  
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 10px;
  }
  
  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 70%;
    margin-top: 20px;
  }
  
  .about2 img {
    width: 330px;
    height: 330px;
    border-radius: 50px;
    margin-right: 80px;
    border: 2px rgb(35, 189, 250) solid;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    margin-bottom: 100px;
    /* From https://css.glass */
    background: rgb(242, 205, 68);
    background: linear-gradient(40deg, rgba(242, 205, 68, 0.3345623230666257) 0%, rgba(40, 27, 47, 0) 35%, rgba(18, 243, 251, 0.269955581493592) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgb(12, 193, 248);
    margin-top: 20px;
    box-shadow: 0 0 15px #64dafd;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .about img {
    width: 50%;
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .video {
    width: 100vh;
    height: 56.25vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
    border: 3px solid white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  .storyH2New{
    display: none;
  }
  .storyH {
    display: flex;
    font-size: 120px;
    color: white;
    font-weight: bold;
    margin-left: auto;
  }
  
  .teamH {
    display: flex;
    font-size: 120px;
    color: white;
    font-weight: bold;
    margin-right: auto;
  }
  
  .storyH2 {
    display: flex;
    font-size: 120px;
    color: white;
    font-weight: bold;
    margin-right: auto;
  }
  
  .storyH3 {
    display: flex;
    font-size: 120px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
  }
  
  .perksMain{
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 33px;
    text-align: left;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon3 {
    font-size: 33px;
    text-align: right;
    color: white;
    margin-top: 55px;
    margin-bottom: 55px;
  }
  
  
  .teamCon {
    font-size: 33px;
    text-align: left;
    color: white;
    margin-top: 55px;
    margin-bottom: 55px;
  }
  
  
  .perksCon {
    font-size: 33px;
    text-align: center;
    color: white;
    margin-top: 26px;
    margin-bottom: 26px;
  }
  
  .storyCon3Hl {
    font-size: 33px;
    text-align: center;
    color: white;
    margin-top: 45px;
    margin-bottom: 45px;
    font-weight: bold;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 100%;
  }
  
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid white;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #7700ff;
    letter-spacing: 2px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 30px;
    margin-right: 30px;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  }
  
  #fontSize:hover {
    color: #13fafa;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc1900;
    color: #1cb5f1;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    width: 250px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    transition: transform .2s;
    font-weight: bold;
    border: 2px solid white;
  }
  
  .btnfos-0-3:hover {
    border: 2px solid #1cb5f1;
    color: white;
  }
  
  .nftamount {
    color: #ffffff;
    font-size: 65px;
    font-family: 'Archivo Black', sans-serif;
    text-align: center;
    -webkit-text-stroke: 2px #21bcfa;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 28px;
    text-shadow: -3px 0 #1ee03e;
    letter-spacing: 2px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
  
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
   
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
 
  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 95%;
    margin-top: -13%;
    position: relative;
    margin-bottom: 20%;
  }
  
    /* Animation */
    .anim-typewriter {
      animation: typewriter 4s steps(44) 1s 1 normal both,
        blinkTextCursor 500ms steps(44) infinite normal;
    }
  
    
  .in2 {
    width: 100%;
    display: flex;
     justify-content: center;
    flex-flow: column nowrap;
    font-size: 150px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
   
  }
  
  
    .line-1 {
      position: relative;
       color: rgb(255, 255, 255);
      font-size: 20px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
        border-right: 4px solid rgba(255, 255, 255, .75);
      width: 100%;
      display: flex;
       justify-content: center;
      flex-flow: column nowrap;
      font-size: 150px;
      color: white;
      font-weight: bold;
      letter-spacing: 3px;
    }
    
    @keyframes typewriter {
      from {
        width: 0;
      }
    
      to {
        width: 1250px;
      }
    }
  
  .blinkBox{
     margin-top: 20%;
     margin-left: 5%;
     z-index: 10000000;
     line-height: 190px;
    }
  
    .box1NFTMob{
      display: none;
     }
     .mainNFTMob{
      width: 50%;
      position: absolute;
      margin-left: 50%;
    }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .mainNFT {
    width: 100%;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    display: none;
  }
   
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: 'Archivo Black', sans-serif;
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 32px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 260px;
    height: 312px;
  }
  
  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 33px;
    cursor: pointer;
    z-index: 10000;
    color: #fafafa;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }
  
  .faq__content {
  
    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #0b0c11;
    height: fit-content;
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 350px;
    height: 350px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }
  
  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }
  
  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }
  
  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }
  
  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container img {
    width: 45%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }
  
  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
  
  }
  
  .container:hover img {
    opacity: 0.3;
  }
  
  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPic {
    width: 100%;
    position: absolute;
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  
  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }
  
  .chatBox1 {
    display: none;
  
  }
  
  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }
  
  .chatBox2 {
    display: none;
  
  }
  
  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }
  
  .chatBox3 {
    display: none;
  
  }
  
  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }
  
  .chatBox4 {
    display: none;
  
  }
  
  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }
  
  .chatBox5 {
    display: none;
  
  }
  
  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }
  
  .chatBox6 {
    display: none;
  
  }
  
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  
  }
  
  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: 'Archivo Black', sans-serif;
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 120px;
    height: 120px
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
    margin-top: -6%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .row1{
    display: flex;
    flex-flow: column nowrap;
    color: white;
    justify-content: space-between;
    }
  
  .coloumn1{
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }  
  
  .figure1{
    font-weight: bold;
    font-size: 45px;
    text-align: center;
  }
  
  .figure2{
    text-align: center;
    font-size: 25px;
  }
  
  .box1{
    display: flex;
    flex-flow: column nowrap;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.116);
  border-radius: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
     margin-top: 20%;
    margin-bottom: 20%;
     margin-left: auto;
    margin-right: auto;
    padding: 40px;
    z-index: 1;
   }
  
  .boxMain{
    position: relative;
  }
  
  .boxMain{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
  
  .figureSection{ 
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .boxCon{
    width: 60%;
    }
   
  .box1NFT{
    width: 35%;
    margin-left: 57%;
      z-index: 10000000000000000;
    position: absolute;
    margin-top: -9.8%;
  }
  
  .box1NFTDiv{
    margin-right: auto;
   }

    
  .nftT{
    color: #4cadc5;
    font-weight: bolder;
    font-size: 42px;
    text-align: center;
    margin-bottom: 8%;  
  }
  
  .nftT2{
    font-size: 42px;
    text-align: center;
    font-weight: bold;
    color: #ac64a5;
    margin-bottom: 8%;
  }
  
  .headerAndCon{
    background-color: #0038f9;
    width: fit-content;
  }
}

@media screen and (min-width: 3840px) {
  .back{
    width: 150px;
    height: 150px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: transform .2s;
  }     
.glitch-wrapper {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
 
}

.glitch {
  color: rgb(255, 255, 255);
  font-size: 250px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  font-family: 'Archivo Black', sans-serif;
  font-weight: bold;
}

.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch::before {
  left: 6px;
  text-shadow: -6px 0 #1ec5fb;
  clip: rect(24px, 550px, 90px, 0);
  -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
}

.glitch::after {
  left: -6px;
  text-shadow: -6px 0 #ba2e79;
  clip: rect(85px, 550px, 140px, 0);
  -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-o-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-moz-keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(110px, 9999999px, 74px, 0);
  }

  4.16666667% {
    clip: rect(135px, 9999999px, 143px, 0);
  }

  8.33333333% {
    clip: rect(100px, 9999999px, 100px, 0);
  }

  12.5% {
    clip: rect(38px, 9999999px, 24px, 0);
  }

  16.66666667% {
    clip: rect(125px, 9999999px, 83px, 0);
  }

  20.83333333% {
    clip: rect(149px, 9999999px, 63px, 0);
  }

  25% {
    clip: rect(12px, 9999999px, 104px, 0);
  }

  29.16666667% {
    clip: rect(68px, 9999999px, 7px, 0);
  }

  33.33333333% {
    clip: rect(77px, 9999999px, 20px, 0);
  }

  37.5% {
    clip: rect(99px, 9999999px, 6px, 0);
  }

  41.66666667% {
    clip: rect(27px, 9999999px, 99px, 0);
  }

  45.83333333% {
    clip: rect(58px, 9999999px, 107px, 0);
  }

  50% {
    clip: rect(108px, 9999999px, 77px, 0);
  }

  54.16666667% {
    clip: rect(58px, 9999999px, 87px, 0);
  }

  58.33333333% {
    clip: rect(142px, 9999999px, 112px, 0);
  }

  62.5% {
    clip: rect(124px, 9999999px, 125px, 0);
  }

  66.66666667% {
    clip: rect(61px, 9999999px, 53px, 0);
  }

  70.83333333% {
    clip: rect(117px, 9999999px, 74px, 0);
  }

  75% {
    clip: rect(21px, 9999999px, 74px, 0);
  }

  79.16666667% {
    clip: rect(117px, 9999999px, 70px, 0);
  }

  83.33333333% {
    clip: rect(87px, 9999999px, 122px, 0);
  }

  87.5% {
    clip: rect(95px, 9999999px, 9px, 0);
  }

  91.66666667% {
    clip: rect(121px, 9999999px, 75px, 0);
  }

  95.83333333% {
    clip: rect(121px, 9999999px, 115px, 0);
  }

  100% {
    clip: rect(101px, 9999999px, 72px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-o-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@-moz-keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}

@keyframes glitch-anim-2 {
  6.66666667% {
    clip: rect(76px, 9999999px, 132px, 0);
  }

  10% {
    clip: rect(13px, 9999999px, 82px, 0);
  }

  13.33333333% {
    clip: rect(97px, 9999999px, 2px, 0);
  }

  16.66666667% {
    clip: rect(1px, 9999999px, 147px, 0);
  }

  20% {
    clip: rect(113px, 9999999px, 124px, 0);
  }

  23.33333333% {
    clip: rect(132px, 9999999px, 130px, 0);
  }

  26.66666667% {
    clip: rect(120px, 9999999px, 32px, 0);
  }

  30% {
    clip: rect(2px, 9999999px, 10px, 0);
  }

  33.33333333% {
    clip: rect(76px, 9999999px, 46px, 0);
  }

  36.66666667% {
    clip: rect(140px, 9999999px, 79px, 0);
  }

  40% {
    clip: rect(37px, 9999999px, 67px, 0);
  }

  43.33333333% {
    clip: rect(133px, 9999999px, 95px, 0);
  }

  46.66666667% {
    clip: rect(6px, 9999999px, 16px, 0);
  }

  50% {
    clip: rect(98px, 9999999px, 123px, 0);
  }

  53.33333333% {
    clip: rect(42px, 9999999px, 88px, 0);
  }

  56.66666667% {
    clip: rect(77px, 9999999px, 110px, 0);
  }

  60% {
    clip: rect(22px, 9999999px, 145px, 0);
  }

  63.33333333% {
    clip: rect(47px, 9999999px, 75px, 0);
  }

  66.66666667% {
    clip: rect(109px, 9999999px, 135px, 0);
  }

  70% {
    clip: rect(118px, 9999999px, 40px, 0);
  }

  73.33333333% {
    clip: rect(74px, 9999999px, 141px, 0);
  }

  76.66666667% {
    clip: rect(59px, 9999999px, 100px, 0);
  }

  80% {
    clip: rect(14px, 9999999px, 32px, 0);
  }

  83.33333333% {
    clip: rect(22px, 9999999px, 28px, 0);
  }

  86.66666667% {
    clip: rect(106px, 9999999px, 74px, 0);
  }

  90% {
    clip: rect(33px, 9999999px, 97px, 0);
  }

  93.33333333% {
    clip: rect(94px, 9999999px, 29px, 0);
  }

  96.66666667% {
    clip: rect(108px, 9999999px, 87px, 0);
  }

  100% {
    clip: rect(5px, 9999999px, 14px, 0);
  }
}    
.connectBtn2 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 45px;
  font-size: 50px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: bold;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 630px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
}

 
.introduction {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 95%;
  margin-top: -13%;
  position: relative;
  margin-bottom: 30%;
}

  /* Animation */
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  
.in2 {
  width: 100%;
  display: flex;
   justify-content: center;
  flex-flow: column nowrap;
  font-size: 200px;
  color: white;
  font-weight: bold;
  letter-spacing: 3px;
 
}


  .line-1 {
    position: relative;
     color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
      border-right: 4px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
     justify-content: center;
    flex-flow: column nowrap;
    font-size: 200px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }
  
  @keyframes typewriter {
    from {
      width: 0;
    }
  
    to {
      width: 1650px;
    }
  }

.blinkBox{
   margin-top: 20%;
   margin-left: 5%;
   z-index: 10000000;
   line-height: 260px;
  }

  .box1NFTMob{
    display: none;
   }
   .mainNFTMob{
    width: 50%;
    position: absolute;
    margin-left: 50%;
  }


.osPic2 {
  width: 130px;
  height: 130px;
  cursor: pointer;
  z-index: 10000;
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  border-radius: 100%;
  margin-left: 35px;
  transition: transform .2s;

  margin-right: 35px;
}

.mintBox1 img{
  width: 60%;  
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.mintBox1{
  flex-flow: column nowrap;
  display: flex;

}

.mintBox{
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  justify-content: space-between;
  width: 50%;
  margin-top: 10%;
  margin-bottom: 10%;
 }


.connectBtn3 {
  color: #9ea8d8;
  border-radius: 5px;
  padding: 10px;
  font-size: 55px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.897);
  border-radius: 100px;
  width: 400px;
  /*box-shadow: -5px -5px #d44e00;*/
  box-shadow: rgba(255, 255, 255, 0.35) -5px -5px 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  transition: transform .2s;
}

.mintCon{
  text-align: center;
  color: rgba(255, 255, 255, 0.699);
  font-weight: bold;
  margin-top: 6%;
  margin-bottom: 6%;
  font-size: 45px; 

}

.mintCon2{
  text-align: left;
  color: white;
 font-weight: bold; 
 margin-left: auto;
 margin-right: auto;
 color: rgba(255, 255, 255, 0.815);
 font-size: 45px; 
}
.listSection{
  margin-left: auto;
  margin-right: auto;
}
  
  .teamCon2{
    display: none;
  }
 
  .box1NFTMob{
    display: none;
   }
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
    color: rgb(0, 0, 0);
  }
  
  
  #container {
    width: 70vw;
    /* margin-left: 30vw auto;
    margin-right: 30vw auto;
    margin-bottom: 50vw auto; */
    margin: 5vw auto;
  
  }
  
  
  h1:nth-child(2) {
    color: #67cbf3;
    animation: glitch2 2.5s infinite;
  }
  
  h1:nth-child(3) {
    color: #e2f2ff;
    animation: glitch3 2.5s infinite;
  }
  
  /*Keyframes*/
  
  @keyframes glitch1 {
    0% {
      transform: none;
      opacity: 1;
    }
  
    7% {
      transform: skew(-0.5deg, -0.9deg);
      opacity: 0.75;
    }
  
    10% {
      transform: none;
      opacity: 1;
    }
  
    27% {
      transform: none;
      opacity: 1;
    }
  
    30% {
      transform: skew(0.8deg, -0.1deg);
      opacity: 0.75;
    }
  
    35% {
      transform: none;
      opacity: 1;
    }
  
    52% {
      transform: none;
      opacity: 1;
    }
  
    55% {
      transform: skew(-1deg, 0.2deg);
      opacity: 0.75;
    }
  
    50% {
      transform: none;
      opacity: 1;
    }
  
    72% {
      transform: none;
      opacity: 1;
    }
  
    75% {
      transform: skew(0.4deg, 1deg);
      opacity: 0.75;
    }
  
    80% {
      transform: none;
      opacity: 1;
    }
  
    100% {
      transform: none;
      opacity: 1;
    }
  }
  
  @keyframes glitch2 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(-2px, -3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(-5px, -2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(-5px, -1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(-2px, -6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  @keyframes glitch3 {
    0% {
      transform: none;
      opacity: 0.25;
    }
  
    7% {
      transform: translate(2px, 3px);
      opacity: 0.5;
    }
  
    10% {
      transform: none;
      opacity: 0.25;
    }
  
    27% {
      transform: none;
      opacity: 0.25;
    }
  
    30% {
      transform: translate(5px, 2px);
      opacity: 0.5;
    }
  
    35% {
      transform: none;
      opacity: 0.25;
    }
  
    52% {
      transform: none;
      opacity: 0.25;
    }
  
    55% {
      transform: translate(5px, 1px);
      opacity: 0.5;
    }
  
    50% {
      transform: none;
      opacity: 0.25;
    }
  
    72% {
      transform: none;
      opacity: 0.25;
    }
  
    75% {
      transform: translate(2px, 6px);
      opacity: 0.5;
    }
  
    80% {
      transform: none;
      opacity: 0.25;
    }
  
    100% {
      transform: none;
      opacity: 0.25;
    }
  }
  
  input {
    width: 200px;
    height: fit-content;
    text-align: center;
    background: rgba(0, 0, 0, 0.575);
    border: 2px #ffffff solid;
  }
  
  .miniBarMain {
    display: none;
  }
  
  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  h1 {
    text-align: center;
    color: #fff;
    font-size: 60px;
    /*letter-spacing: 8px;
      font-family: "Press Start 2P";
      font-weight: 400;*/
    /*Create overlap*/
    font-family: 'Archivo Black', sans-serif;
  
    margin: 0;
    line-height: 0;
    /*Animation*/
  
    animation: glitch1 2.5s infinite;
  }
  
  .littleNav {
    display: none;
  }
  
  .navbarMain {
    display: none;
  }
  
  .tokenHSub {
    color: #d1dff8;
    font-weight: 500;
    font-size: 20px;
  }
  
  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    color: #9ea8d8;
    border-radius: 5px;
    padding: 25px;
    font-size: 55px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.897);
    border-radius: 100px;
    width: 400px;
    /*box-shadow: -5px -5px #d44e00;*/
    box-shadow: rgba(255, 255, 255, 0.35) -10px -10px 20px;
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
   
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 140px;
    height: 140px;
    cursor: pointer;
    z-index: 10000;
    box-shadow: rgba(255, 255, 255, 0.35) -10px -10px 20px;
    border-radius: 100%;
    margin-left: 8px;
    margin-right: 8px;
   }

  .price {
    text-align: center;
    font-size: 22px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .anim-typewriter {
    animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .line-2 {
    position: relative;
    top: 50%;
    width: 48%;
    text-align: center;
    font-size: 20px;
    color: #acf9ff;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    margin-left: auto;
    margin-right: auto;
  }
  
  .anim-typewriter2 {
    animation: typewriter2 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }
  
  @keyframes typewriter2 {
    from {
      width: 0;
    }
  
    to {
      width: 260px;
    }
  }
  
  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }
  
    to {
      border-right-color: transparent;
    }
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background-color: #0b0c11;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  
    padding-top: 100px;
    padding-bottom: 20px;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100475218662789181/NFTHouse9_blockchain_network_background_image_cyber_punk_theme__3ce5a6b3-d55d-4562-94e6-ed143a568181.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
   
  }
  
  .perks {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: #040d1a;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: linear-gradient(0deg, rgba(4,13,26,1) 0%, rgba(4,13,26,0) 47%, rgba(11,12,17,0.8239670868347339) 100%), url('./assets/nft.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  .videoContainer {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1100459043526561934/NFTHouse9_doge_coin_cyber_punk_theme_64k_6dc18e25-ad22-487f-9d98-6eada8a42fd1.png?width=725&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #040d1a;
  
  }
  
  .footer {
    padding-bottom: 5%;
    padding-top: 5%;
    display: flex;  
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
   }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 10px;
  }
  
  .aboutMain {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
    margin-top: auto;
    margin-bottom: auto;
   }
  
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 70%;
    margin-top: 20px;
  }
  
  .about2 img {
    width: 330px;
    height: 330px;
    border-radius: 50px;
    margin-right: 80px;
    border: 2px rgb(35, 189, 250) solid;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 70%;
    margin-bottom: 100px;
    /* From https://css.glass */
    background: rgb(242, 205, 68);
    background: linear-gradient(40deg, rgba(242, 205, 68, 0.3345623230666257) 0%, rgba(40, 27, 47, 0) 35%, rgba(18, 243, 251, 0.269955581493592) 100%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgb(12, 193, 248);
    margin-top: 20px;
    box-shadow: 0 0 15px #64dafd;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .about img {
    width: 50%;
    height: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .video {
    width: 100vh;
    height: 56.25vh;    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
    border: 3px solid white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  .storyH2New{
    display: none;
  }
  .storyH {
    display: flex;
    font-size: 165px;
    color: white;
    font-weight: bold;
    margin-left: auto;
  }
  
  .teamH {
    display: flex;
    font-size: 165px;
    color: white;
    font-weight: bold;
    margin-right: auto;
  }
  
  .storyH2 {
    display: flex;
    font-size: 165px;
    color: white;
    font-weight: bold;
    margin-right: auto;
  }
  
  .storyH3 {
    display: flex;
    font-size: 165px;
    color: white;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
  
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: 'Archivo Black', sans-serif;
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: left;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
  }
  
  .perksMain{
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: left;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 50px;
    text-align: left;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .storyCon3 {
    font-size: 50px;
    text-align: right;
    color: white;
    margin-top: 85px;
    margin-bottom: 85px;
  }
  
  
  .teamCon {
    font-size: 50px;
    text-align: left;
    color: white;
    margin-top: 85px;
    margin-bottom: 85px;
  }
  
  
  .perksCon {
    font-size: 50px;
    text-align: center;
    color: white;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .storyCon3Hl {
    font-size: 50px;
    text-align: center;
    color: white;
    margin-top: 70px;
    margin-bottom: 70px;
    font-weight: bold;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-radius: 100%;
  }
  
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 3px solid white;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    text-shadow: -2.5px 0 red;
    letter-spacing: 2px;
    text-align: center;
    color: white;
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: white;
    text-shadow: -2px 0 #7700ff;
    letter-spacing: 2px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 40px;
    margin-right: 40px;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  }
  
  #fontSize:hover {
    color: #13fafa;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #6cfc1900;
    color: #1cb5f1;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    width: 250px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    transition: transform .2s;
    font-weight: bold;
    border: 2px solid white;
  }
  
  .btnfos-0-3:hover {
    border: 2px solid #1cb5f1;
    color: white;
  }
  
  .nftamount {
    color: #ffffff;
    font-size: 65px;
    font-family: 'Archivo Black', sans-serif;
    text-align: center;
    -webkit-text-stroke: 2px #21bcfa;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: white;
  
    font-size: 28px;
    text-shadow: -3px 0 #1ee03e;
    letter-spacing: 2px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
  
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
   
    z-index: 1000000;
    padding-top: 30px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .mainNFT {
    width: 100%;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    display: none;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: 'Archivo Black', sans-serif;
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 43px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 350px;
    height: 420px;
  }
  
  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 50px;
    cursor: pointer;
    z-index: 10000;
    color: #fafafa;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 21px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #ffffff;
    padding: 0.5rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border-bottom: #8b8b8b 2px solid;
    font-weight: bold;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: rgb(255, 252, 252);
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    background-color: #000000ab;
    border-radius: 20px;
    padding: 50px;
    border: 3px white solid;
  }
  
  .faq__content {
  
    color: #ffffff;
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 100px;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://gogeassets.pages.dev/bg/faqBg.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    background-color: #0b0c11;
    height: fit-content;
  }
  
  .web {
    width: 100%;
    height: 100%;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    animation: falling 5s linear infinite;
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 25px;
    color: #ffffff;
    text-shadow: 0 0 10px #5bbcfc;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .tPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  
  .man {
    width: 350px;
    height: 350px;
  }
  
  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  .honeyComb {
    width: 450px;
    height: 345px;
    position: sticky;
    position: -webkit-sticky;
    left: 80%;
    bottom: 80px;
  }
  
  .dripCover {
    width: 100%;
  }
  
  .ball {
    width: 100px;
    height: 100px;
    animation: floating 2s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 200px;
  }
  
  .ball4 {
    width: 130px;
    height: 130px;
    animation: floating4 2.1s ease-in-out infinite;
    margin-top: -200px;
    margin-left: 250px;
  }
  
  .ball5 {
    width: 130px;
    height: 130px;
    animation: floating4 2.3s ease-in-out infinite;
    margin-top: -400px;
    margin-left: 200px;
  }
  
  .ball6 {
    width: 130px;
    height: 130px;
    animation: floating4 2.8s ease-in-out infinite;
    margin-top: -1100px;
    margin-right: 600px;
  }
  
  .ball7 {
    width: 130px;
    height: 130px;
    animation: floating4 3s ease-in-out infinite;
    margin-top: -1000px;
    margin-left: 1600px;
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating2 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating3 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes floating4 {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: 'Archivo Black', sans-serif;
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container img {
    width: 45%;
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  
    transform-origin: center;
    transform: perspective(800px) rotateY(25deg);
    transition: 0.5s;
  }
  
  .container {
    max-width: 850px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
  
  }
  
  .container:hover img {
    opacity: 0.3;
  }
  
  .container img:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPic {
    width: 100%;
    position: absolute;
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .stadium1 {
    width: 18%;
    margin-right: 63%;
    margin-top: 11%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  
  .stadium1:hover+.chatBox1 {
    cursor: pointer;
    margin-top: 4%;
    z-index: 10000;
    width: 35%;
    display: block;
    margin-right: 220px;
  }
  
  .chatBox1 {
    display: none;
  
  }
  
  .stadium2:hover+.chatBox2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 20%;
    margin-top: 2%;
  }
  
  .chatBox2 {
    display: none;
  
  }
  
  .stadium3:hover+.chatBox3 {
    cursor: pointer;
    margin-top: 20%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 58%;
  }
  
  .chatBox3 {
    display: none;
  
  }
  
  .stadium4:hover+.chatBox4 {
    cursor: pointer;
    margin-top: 36%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 33%;
  }
  
  .chatBox4 {
    display: none;
  
  }
  
  .stadium5:hover+.chatBox5 {
    cursor: pointer;
    margin-top: 48%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 38%;
  }
  
  .chatBox5 {
    display: none;
  
  }
  
  .stadium6:hover+.chatBox6 {
    cursor: pointer;
    margin-top: 60%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
  }
  
  .chatBox6 {
    display: none;
  
  }
  
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .stadium2 {
    width: 18%;
    margin-left: 22%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium3 {
    width: 18%;
    margin-right: 57%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  
  }
  
  .stadium4 {
    width: 18%;
    margin-left: 33%;
    margin-top: 55%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: 'Archivo Black', sans-serif;
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 150px;
    height: 150px
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
    margin-top: -6%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .row1{
    display: flex;
    flex-flow: column nowrap;
    color: white;
    justify-content: space-between;
    }
  
  .coloumn1{
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }  
  
  .figure1{
    font-weight: bold;
    font-size: 65px;
    text-align: center;
  }
  
  .figure2{
    text-align: center;
    font-size: 50px;
  }
  
  .box1{
    display: flex;
    flex-flow: column nowrap;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.116);
  border-radius: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
     margin-top: 20%;
    margin-bottom: 20%;
     margin-left: auto;
    margin-right: auto;
    padding: 40px;
    z-index: 1;
   }
  
  .boxMain{
    position: relative;
  }
  
  .boxMain{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
  
  .figureSection{ 
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .boxCon{
    width: 60%;
    }
   
  .box1NFT{
    width: 35%;
    margin-left: 57%;
      z-index: 10000000000000000;
    position: absolute;
    margin-top: -9.8%;
  }
  
  .box1NFTDiv{
    margin-right: auto;
   }

    
  .nftT{
    color: #4cadc5;
    font-weight: bolder;
    font-size: 60px;
    text-align: center;
    margin-bottom: 8%;  
  }
  
  .nftT2{
    font-size: 60px;
    text-align: center;
    font-weight: bold;
    color: #ac64a5;
    margin-bottom: 8%;
  }
  
  .headerAndCon{
    background-color: #0038f9;
    width: fit-content;
  }
}
